import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, interval} from 'rxjs';
import {filter, mergeMap, map, switchMap, first, tap, mapTo} from 'rxjs/operators';


@Injectable(
  {providedIn: 'root'}
)
export class SnackService {
  private stack = new BehaviorSubject([]as any);
  private hasMessages = new BehaviorSubject(false);
  $stack = this.stack.asObservable();
  $hasMessages = this.hasMessages.asObservable();
  constructor() {
    this.$stack
      .pipe(
        filter(list => list.length > 0),
        mergeMap(list => list),
        switchMap((item: any) => interval(item.duration).pipe(first())),
      )
      .subscribe(() => {
        const newArr = this.stack.value.filter((x: any, i: number) => i !== 0);
        this.stack.next([...newArr]);
      });
  }
  close(index: number) {
    this.stack.next([...this.stack.value.filter((x: any, i: number) => i !== index)]);
  }
  newAlert(message: any, duration: any) {
    this.stack.next([...this.stack.value, {message, duration}]);
  }
}

interface Message {
  message: string;
  duration: number;
}
