/* tslint:disable:max-line-length */
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import {A11yModule} from '@angular/cdk/a11y';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {PortalModule} from '@angular/cdk/portal';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {CdkStepperModule} from '@angular/cdk/stepper';
import {CdkTableModule} from '@angular/cdk/table';
import {CdkTreeModule} from '@angular/cdk/tree';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatBadgeModule} from '@angular/material/badge';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatStepperModule} from '@angular/material/stepper';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTreeModule} from '@angular/material/tree';
import {OverlayModule} from '@angular/cdk/overlay';
import {PrettyJsonModule} from 'angular2-prettyjson';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {FinanceappComponent} from './Pages/financeapp/financeapp.component';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { AlertsComponent } from './alerts/alerts.component';
import { MsalModule } from '@azure/msal-angular';
import { TwdSearchComponent } from './Pages/twd-search/twd-search.component';
import { HourappComponent } from './Pages/hourapp/hourapp.component';
import { HomepageComponent } from './Pages/homepage/homepage.component';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
// tslint:disable-next-line: max-line-length
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WorkshopappComponent } from './Pages/workshopapp/workshopapp.component';
import {HttpClientModule} from '@angular/common/http';
import { PhonelistComponent } from './Pages/homepage/overviews/phoneList/phonelist.component';
// import { SnackModule } from './snack/snack.module';
import { InvoiceTableComponent } from './Pages/financeapp/facturen_overzicht/table-http-example';
import { BalieLogComponent } from './Pages/financeapp/Balie-Log/Balie-Log.component';
import { FormatterComponent } from './Pages/financeapp/formatter/formatter.component';
import { GenFinMutatiesService } from './Pages/financeapp/facturen_overzicht/GenFinMutaties.service';
import { GenBalieDirectService } from './Pages/financeapp/facturen_overzicht/GenBalieDirect.service';
import { DatePipe } from '@angular/common';
import { NotificationService } from './notification-service/notification.service';
import { SnackService } from './snack/snack.service';
import { GenHTMLRegelsService } from './Pages/financeapp/facturen_overzicht/GenHTMLRegels.service';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { OrganogramComponent } from './Pages/organogram/organogram.component';
import { JoyrideModule } from 'ngx-joyride';

import { Workshopapp_v2Component } from './Pages/workshopapp_v2/workshopapp_v2.component';
import { Workshop_attendees_dialogComponent } from './Pages/workshopapp_v2/workshop_attendees_dialog/workshop_attendees_dialog.component';
import { SplitPipe } from './pipes/split.pipe';
import { AutodeskComponent } from './Pages/licenses/autodesk/autodesk.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import {FlexLayoutModule, FlexModule} from '@angular/flex-layout';
import { IctComponent } from './Pages/ict/ict.component';
import { MobileTelephonyComponent } from './Pages/ict/mobile-telephony/mobile-telephony.component';
import { HardwareListComponent } from './Pages/ict/hardware-list/hardware-list.component';
import { PhoneListComponent } from './Pages/ict/phone-list/phone-list.component';
import { PhoneListDialogComponent } from './Pages/ict/phone-list/phone-list-dialog/phone-list-dialog.component';
import { MobileTelephonyDialogComponent } from './Pages/ict/mobile-telephony/mobile-telephony-dialog/mobile-telephony-dialog.component';
import { HardwareListDialogComponent } from './Pages/ict/hardware-list/hardware-list-dialog/hardware-list-dialog.component';
import { SpinnerOverlayComponent } from './spinner-overlay/spinner-overlay.component';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import {SnackContainerComponent} from './snack/snack-container.component';
import {SnackComponent} from './snack/snack.component';
import { LicensesComponent } from './Pages/licenses/licenses.component';
import { RfemComponent } from './Pages/licenses/rfem/rfem.component';
import { AnsysComponent } from './Pages/licenses/ansys/ansys.component';
import {environment} from '../environments/environment';
import { ExperthubComponent } from './Pages/experthub/experthub.component';
import { ExperthubDialogComponent } from './Pages/experthub/experthub-dialog/experthub-dialog.component';
import { UsersDialogComponent } from './Pages/experthub/users-dialog/users-dialog.component';
import { WoskhopOverviewComponentV3 } from './Pages/workshopapp_v3/woskhop-overview/woskhop-overview.component';
import { MyWorkshopsOverviewComponentV3 } from './Pages/workshopapp_v3/my-workshops-overview/my-workshops-overview.component';
import { CursusDataOverviewComponentV3 } from './Pages/workshopapp_v3/cursus-data-overview/cursus-data-overview.component';
import {workshopAppV3Component} from './Pages/workshopapp_v3/workshopapp_v3.component';
import {
  Workshop_attendees_dialogComponent_V3
} from './Pages/workshopapp_v3/workshop_attendees_dialog/workshop_attendees_dialog.component';
// import { NgxMatDatetimePickerModule } from '@angular-material-components/datetime-picker';

// library.add(faExternalLinkAlt);
// library.add(faUserCircle);

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

// Redirect URI for development or production
const redirectUri = environment.redirectUri;


@NgModule({
    declarations: [
        AppComponent,
        NavBarComponent,
        AlertsComponent,
        TwdSearchComponent,
        HourappComponent,
        HomepageComponent,
        WorkshopappComponent,
        Workshopapp_v2Component,
        workshopAppV3Component,
        PhonelistComponent,
        FinanceappComponent,
        InvoiceTableComponent,
        BalieLogComponent,
        FormatterComponent,
        SnackContainerComponent,
        OrganogramComponent,
        Workshop_attendees_dialogComponent,
        Workshop_attendees_dialogComponent_V3,
        SplitPipe,
        SnackComponent,
        AutodeskComponent,
        IctComponent,
        MobileTelephonyComponent,
        HardwareListComponent,
        PhoneListComponent,
        PhoneListDialogComponent,
        MobileTelephonyDialogComponent,
        HardwareListDialogComponent,
        SpinnerOverlayComponent,
        LicensesComponent,
        RfemComponent,
        AnsysComponent,
        ExperthubComponent,
        ExperthubDialogComponent,
        UsersDialogComponent,
        WoskhopOverviewComponentV3,
        MyWorkshopsOverviewComponentV3,
        CursusDataOverviewComponentV3,
    ],
    imports: [
        BrowserModule,
        CommonModule,
        // material stuffs
        A11yModule,
        BrowserAnimationsModule,
        ClipboardModule,
        CdkStepperModule,
        CdkTableModule,
        CdkTreeModule,
        DragDropModule,
        HttpClientModule,
        FormsModule,
        CommonModule,
        MatIconModule,
        MatButtonModule,
        FlexLayoutModule,
        MatAutocompleteModule,
        MatBadgeModule,
        MatBottomSheetModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatStepperModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatTreeModule,
        OverlayModule,
        PortalModule,
        PdfViewerModule,
        ReactiveFormsModule,
        ScrollingModule,
        AppRoutingModule,
        NgbModule,
        // SnackModule,
        PrettyJsonModule,
        NgxJsonViewerModule,
        FontAwesomeModule,
        MDBBootstrapModule.forRoot(),
        JoyrideModule.forRoot(),
        MsalModule.forRoot(new PublicClientApplication({
            auth: {
                clientId: '9e372526-d7d3-4986-9e92-5179228529c3',
                authority: 'https://login.microsoftonline.com/4fc4e136-4a65-4aec-8d3f-575155b66940',
                redirectUri // This is your redirect URI
            },
            cache: {
                cacheLocation: 'localStorage',
                storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
            },
        }), {
            interactionType: InteractionType.Popup,
            authRequest: {
                scopes: ['user.read']
            }
        }, {
            interactionType: InteractionType.Popup,
            protectedResourceMap: new Map([
                ['https://graph.microsoft.com/v1.0/me', ['user.read']]
            ])
        }),
        FlexModule
    ],
    schemas: [
      CUSTOM_ELEMENTS_SCHEMA
    ],
    providers: [GenFinMutatiesService,
        GenBalieDirectService,
        DatePipe,
        NotificationService,
        SnackService,
        GenHTMLRegelsService,
        BalieLogComponent,
        NavBarComponent,
        OrganogramComponent,
        Workshopapp_v2Component,
        workshopAppV3Component,
        Workshop_attendees_dialogComponent,
        Workshop_attendees_dialogComponent_V3,
        AutodeskComponent,
        PhoneListComponent,
        PhoneListDialogComponent,
        NotificationService,
        MatSnackBarModule,
        SpinnerOverlayComponent,
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
        { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 5000 } },
    ],
    bootstrap: [AppComponent, FinanceappComponent, Workshop_attendees_dialogComponent, AutodeskComponent]
})
export class AppModule { }
