<div class="row" *ngIf="authService.userLogged">
  <div class="col-12">
    <div class="card main-card mb-3">
      <div class="card-header">
        Ansys ({{ansysLicenseData.length}}/5)
      </div>
      <div class="card-body ta_container">
        <app-ansys></app-ansys>
      </div>
    </div>
  </div>
  <div class="col-12">
    <div class="card main-card mb-3">
      <div class="card-header">
        Rfem License Usage  ({{rfemLicenseData.rfemUserList.length}}/{{rfemLicenseData.rfemFeaturesList.logl}})
      </div>
      <div class="card-body ta_container">
        <app-rfem></app-rfem>
      </div>
    </div>
  </div>
</div>
