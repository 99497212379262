<div class="row" *ngIf="authService.userLogged">
  <div class="col-6">
    <div class="card main-card mb-3">
      <div class="card-header">
        TA Overview
      </div>
      <div class="card-body ta_container">
<!--        <app-ta-list></app-ta-list>-->
      </div>
    </div>
  </div>

  <div class="col-6">
    <div class="card main-card mb-3">
      <div class="card-header">
        Internal PhoneList
      </div>
      <div class="card-body phonelist_container" >
        <app-phonelist></app-phonelist>
      </div>
    </div>
  </div>
</div>
