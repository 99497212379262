<div>
  <app-spinner-overlay *ngIf="isLoading">{{ isLoading }}</app-spinner-overlay>


  <div class="attendees_tab_header">
    <h5>Attendance List</h5>
    <p>Select employees that attended the workshop</p>
  </div>

  <table mat-table [dataSource]="tableData" class="mat-elevation-z8">

    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
<!--        <mat-checkbox (change)="$event ? masterToggle() : null"-->
<!--                      [checked]="selection.hasValue() && isAllSelected()"-->
<!--                      [indeterminate]="selection.hasValue() && !isAllSelected()">-->
<!--        </mat-checkbox>-->
        Present
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="$event ? selection.toggle(row) : null"
                      (change)="setAttendance($event, row)"
                      [checked]="selection.isSelected(row)"
                      [checked]="row.attendeeStatus">
        </mat-checkbox>
      </td>
    </ng-container>


    <ng-container matColumnDef="Attendee">
      <th mat-header-cell *matHeaderCellDef> Emp. </th>
      <td mat-cell *matCellDef="let row">{{row.attendeeABB}}</td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let row">{{row.attendeeABB}}</td>
    </ng-container>


    <ng-container matColumnDef="group">
      <th mat-header-cell *matHeaderCellDef> Date Registered </th>
      <td mat-cell *matCellDef="let row"> {{row.dateJoined | date:'dd-MM-yyyy'}} </td>
    </ng-container>


    <ng-container matColumnDef="manager">
      <th mat-header-cell *matHeaderCellDef> Manager </th>
      <td mat-cell *matCellDef="let row"> {{row.attendeeManager}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <div>
    <button class="twd_btn" style="margin-bottom: 15px; margin-top: 15px; float: right;" mat-raised-button
    (click)="updateAttendanceAfasData()">save</button>
  </div>

 </div>
