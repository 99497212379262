import {Injectable} from '@angular/core';
import {Client} from '@microsoft/microsoft-graph-client';
import {AuthService} from './auth.service';
import {Event} from './models/event';
import {AlertsService} from './alerts.service';
import {HttpClient} from '@angular/common/http';
import {User} from './models/user';

@Injectable({
  providedIn: 'root'
})
export class GraphService {

  public user: User;
  private localToken: string;
  public currentDate: any = new Date(Date.now());
  public currentDateFormatted: string;
  public graphClient: Client;

  // create empty list to use for filtering names and get abbreviation
  public basenameList = [];

  constructor(
    private authService: AuthService,
    private alertsService: AlertsService,
    private httpClient: HttpClient) {

    // Initialize the Graph client
    this.graphClient = Client.init({
      authProvider: async (done) => {
        // Get the token from the auth service
        const token = sessionStorage.accessToken;

        if (token) {
          done(null, token);
          this.localToken = token;
        } else {
          done('Could not get an access token', null);
        }
      }
    });
  }

  async getEvents(date): Promise<Event[]> {
    try {
      const result =  await this.graphClient

      // GET MY CALENDAR
        // .api('/me/events')
      // GET BROADCASTING CHANNEL CALENDAR
      //
        .api('groups/{06564bba-5918-46bd-90ab-a58ac6ab2b9b}/events?$top=100&filter=start/dateTime ge \'' + date + '\'')
        .select('subject,organizer,start,end,bodyPreview,attendees,categories,body')
        .orderby('createdDateTime DESC')
        .get();


      return result.value;

    } catch (error) {
      this.alertsService.add('Could not get events', JSON.stringify(error, null, 2));
    }
  }


  // get broadcasting list of messages
  async getBroadcastingChannelMessages() {
    try {
      const broadcastingChannelMessages = await this.graphClient
        .api('/teams/4131ef2d-9829-4120-8eca-a60243af87b8/channels/19:2e589578543c48898c91b0bbc433e1b2@thread.tacv2/messages')
        .version('beta')
        .headers({key: 'Authorization', value: 'bearer' + this.localToken})
        .get();


      return broadcastingChannelMessages.value;
      } catch (error) {
        this.alertsService.add('Could not get events', JSON.stringify(error, null, 2));
      }
  }

  // get list of meetingroom calendars
  async getMeetingRooms() {
    try{
      const meetingRooms = await this.graphClient
        .api('/me/findRooms')
        .version('beta')
        .get();

      return meetingRooms.value;
    } catch (error) {
      this.alertsService.add('Could not get Meetingrooms', JSON.stringify(error, null, 2));
    }

  }

  // update event when user sign up for workshop
  async updateEvents(dataForUpdate, eventID) {

    const res = await this.graphClient.api('/groups/{06564bba-5918-46bd-90ab-a58ac6ab2b9b}/calendar/events/' + eventID)
      .update(dataForUpdate);
  }

  // create event in workshop calendar
  async createEvents(dataForUpdate) {
      const res = await this.graphClient.api('/groups/{06564bba-5918-46bd-90ab-a58ac6ab2b9b}/calendar/events/')
      // let res = await this.graphClient.api('/me/events/')
        .post(dataForUpdate);

      const teamsMeetingUrl = res.onlineMeeting.joinUrl;
      const workshopOutlookId = res.id;
      return [teamsMeetingUrl, workshopOutlookId];
  }

  async addToCalendar(dataForUpdate) {
    const res = await this.graphClient.api('/me/events/')
        .post(dataForUpdate);
  }

  // format date to YYY-MM-DD
  formatDate(currentDate) {
    let d = new Date(currentDate),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) {
        month = '0' + month;
    }
    if (day.length < 2) {
        day = '0' + day;
    }

    return [year, month, day].join('-');
}

  async getUser(): Promise<User> {
    // if (!this.authService.userLogged) { return null; }

    console.log('getting user!!');
    // Get the user from Graph (GET /me)
    const graphUser = await this.graphClient.api('/me')
      .select('displayName,jobTitle,mail,mobilePhone,userPrincipalName,proxyAddresses')
      .get();

    const user = new User();
    console.log('fist log from get user function', graphUser);
    // DETERMINE WHAT USER DATA YOU WANT TO SEE
    user.displayName = graphUser.displayName;
    user.email = graphUser.mail || graphUser.userPrincipalName;
    user.jobTitle = graphUser.jobTitle || graphUser.jobTitle;
    user.mobilePhone = graphUser.mobilePhone || graphUser.mobilePhone;
    user.proxyAddresses = graphUser.proxyAddresses || graphUser.proxyAddresses;
    user.abb = this.getAbb(graphUser.proxyAddresses);
    user.avatar = this.getUserPhoto();

    console.log('userdata from getuser funtion: ', user);

    return user;
  }

  getAbb(proxyAddresses) {
    for (const proxyAddress of proxyAddresses) {
      // split the proxy addresses and remove @twd.nl and smtp:
      const basename = proxyAddress.substr(5).split('@', 1);
      // append split proxy addresses to list
      this.basenameList.push(basename);
      // console.log(this.basenameList)

      for (const baseName of this.basenameList) {
        // console.log(basename)
        // check if basename length = 3 (RDI) and return
        if (baseName[0].length === 3) {
          console.log('your abbreviation is: ', baseName[0]);
          sessionStorage.setItem('userAbb', baseName[0]);
          return baseName[0];
        }
      }
    }
  }

  getUserPhoto() {
    const photoFolder = '../assets/avatars/';
    const userPhoto = photoFolder + sessionStorage.userAbb + '.png';

    console.log('this is the userphoto: ' , userPhoto);
    sessionStorage.setItem('userPhoto', userPhoto);
    return userPhoto;

  }

}

