// tslint:disable: max-line-length
import { Injectable, ErrorHandler, Inject, Component } from '@angular/core';
import { FactuurModel, BalieIssue, VerdichteRegel, VerdichteRegelsCollection, HttpResponseBalie, FactuurLogInterface } from './main_models';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { catchError, map, elementAt } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { NotificationService } from '../../../notification-service/notification.service';
import { BalieLogComponent } from '../Balie-Log/Balie-Log.component';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GridColumnStyleBuilder } from '@angular/flex-layout/grid/typings/column/column';
import * as XLSX from 'xlsx';
import {environment} from '../../../../environments/environment';
// import { flatten as flattenJsonData } from '@angular/compiler';

@Injectable({
  providedIn: 'root',

})
export class GenBalieDirectService {

  constructor(
    private httpClient: HttpClient,
    private datePipe: DatePipe,
    private Notification_Service: NotificationService,
    private BalieLog: BalieLogComponent,
    public dialog: MatDialog,

  ) { }
  // GET TO
  private href = environment.gen_BalieDirect_url;
  private href_update = environment.update_status_url;
  private twdRestUrl = environment.facturatieWeekUrl;
  private afasLogBaliedirectUrl = environment.afasLogBaliedirectUrl;
  // CREATE NEW ARRAY WITH FACTUURNUMMERS
  private jsonIdDatabase: any = [];
  // Create counter
  private responseCounter = 0;


  public error: any;
  private currentDate = new Date();
  private httpOptions2 = {
    headers: new HttpHeaders({ 'content-type': 'application/json', Authorization: environment.token })
  };
  private httpOptionsRest = {
    headers: new HttpHeaders({ 'content-type': 'application/json', Authorization: environment.token })
  };

  // Update connector for balie direct
  Send_Baliedirect_to_AFAS(SelectedVerdichteRegels: VerdichteRegel[], SelectedInvoices: FactuurModel[], facturatieWeekNummer: any, send_to_afas: boolean) {

    // This function sends data to AFAS

    // Make the HTTP headers required for sending to AFAS
    const httpOptions = {
      headers: new HttpHeaders({ 'content-type': 'application/json', Authorization: environment.token }),
    };

    // GET DATE IN AFAS FORMAT
    this.datePipe.transform(this.currentDate, 'yyyy-MM-dd');

    // Reset counter
    this.responseCounter = 0;

    // Loop through all selected invoices, and generate data for sending to AFAS
    // The data to be send to AFAS depends on the type of invoice (termijn for example)
    for (const selected_invoice of SelectedInvoices) {

      // Container for selected regels of this invoice.
      const filtered_factuurregels_data_for_update = [];

        // Check if this invoice is a termijnfactuur or a normal factuur
      if (selected_invoice.Termijnfacturen === true) {
          for (const verdichte_regel_termijnfactuur of SelectedVerdichteRegels) {
            if (verdichte_regel_termijnfactuur.regelnummers[0].Factuurnummer === selected_invoice.Factuurnummer) {
              for (const regel of verdichte_regel_termijnfactuur.regelnummers) {
                const  data_for_update_lumpsum = {
                  Fields: {
                    VaIt: '6',
                    ItCd: regel.Itemcode,
                    BiUn: '*****',
                    QuUn: regel.Aantal_eenheden,
                    CoPr: '0',
                    Upri: regel.Prijs_per_eenheid,
                    Ds: regel.Omschrijving
                    }
                  };
                // REGELS WEGSCHRIJVEN NAAR DE FACTUUR REGELS LIJSTEN VOOR LUMPSUM
                filtered_factuurregels_data_for_update.push(data_for_update_lumpsum);
                // LOG FACTUURREGELS
                console.log('lumpsum', filtered_factuurregels_data_for_update);
              }
            }
          }
        } else {
            // Loop through selected verdichte regels. These are all the selected lines in the current table state (can be from multiple invoices)
            for (const selected_verdichte_regel of SelectedVerdichteRegels) {

              // Check if verdichte regel belongs to this factuur
              if (selected_verdichte_regel.regelnummers[0].Factuurnummer === selected_invoice.Factuurnummer) {
                // IF STATEMENT OM DE REGELFORMAT TE BEPALEN
                if (selected_verdichte_regel.regelnummers[0].Type_regel === 1) {
                  // This is the format for type 1
                  const  data_for_update = {
                    Fields: {
                      VaIt: '1',
                      ItCd: selected_verdichte_regel.regelnummers[0].Itemcode,
                      BiUn: 'UUR',
                      QuUn: selected_verdichte_regel.hours,
                      CoPr: '0',
                      Upri: selected_verdichte_regel.Prijs_per_eenheid,
                      }
                    };

                  // REGELS WEGSCHRIJVEN NAAR DE FACTUUR REGELS LIJSTEN VOOR LUMPSUM
                  filtered_factuurregels_data_for_update.push(data_for_update);
                  // LOG FACTUURREGELS
                  console.log(filtered_factuurregels_data_for_update);

                } else {
                    // For every regel in the verdichte regel, add it to the regeldict
                    for (const afas_regels of SelectedVerdichteRegels) {
                      // FOR LOOP OM DE FACTUUR REGELS TE GENEREREN VOOR EEN LUMPSUM FACTUUR
                      const  data_for_update = {
                        Fields: {
                          VaIt: '6',
                          ItCd: afas_regels.regelnummers[0].Itemcode,
                          BiUn: '*****',
                          QuUn: afas_regels.hours,
                          CoPr: '0',
                          Upri: afas_regels.Prijs_per_eenheid,
                        }
                      };
                    // REGELS WEGSCHRIJVEN NAAR DE FACTUUR REGELS LIJSTEN VOOR LUMPSUM
                      filtered_factuurregels_data_for_update.push(data_for_update);
                    // LOG FACTUURREGELS LUMPSUM
                      console.log('lumpsum regels', filtered_factuurregels_data_for_update);
                    }
                  }
                }
              }
            }
            // Check if there are double elements in the filtered_factuur_regels
      const orilenght = filtered_factuurregels_data_for_update.length;
      const newlength = (new Set(filtered_factuurregels_data_for_update)).size;
      const double_check = orilenght === newlength;
      if (!double_check) {
              console.log('Double check if the outerdata has double items');
            }

      // GENEREER AFAS JSON PAYLOAD
      const outerdata = {
        FbDirectInvoice: {
          Element: {
            Fields: {
              OrNu: selected_invoice.Factuurnummer,
              OrDa: selected_invoice.Factuurdatum,
              DbId: selected_invoice.Eindklant_nr,
              Unit: '2',
              CuId: selected_invoice.Valutasymbool,
              U927AF7294845D430AFB6D4B78FCD0E3F: selected_invoice.Invoiceweek,
              U6D3D869C4552A646B6F83A8939B29E79: selected_invoice.Project,
              U36F5A002449890FA2F03C5907CFFFC64: true,
              UBC4A93484C07DB8B29D5DBA0BF5E51C3: this.currentDate ,
            },
            Objects: [
              {
              FbDirectInvoiceLines: {
                Element: filtered_factuurregels_data_for_update
                }
              }
            ]
          }
        }
      };

      console.log('dit is de outerdata JSON', outerdata);

      if (send_to_afas === true) {

        // UPDATE CONNECTOR VOOR BALIEDIRECT LUMPSUM FACTUREN UITVOEREN
        this.httpClient.post(this.href, JSON.stringify(outerdata), httpOptions)
        .pipe( // FOUTMELDINGEN WEERGEVEN
          map( response => {
            console.log('dit is wat afas word ingestuurd', response);

            // Update the export check in AFAS
            this.baliedirectVoltooid (selected_invoice.Administratie, selected_invoice.Factuurnummer);
            this.Notification_Service.sendSnack('BalieDirect voor ' + selected_invoice.Factuurnummer + ' succesvol voltooid !', 5000);
            }),
          catchError(this.handleError<any>('getheroes', selected_invoice.Factuurnummer))
          )
        .subscribe();
      } else {
        // WRITE TO LOGBOEK
      const factuurUniqueId = facturatieWeekNummer + '-' + selected_invoice.Factuurnummer;
      this.BalieLogUpdate(outerdata, factuurUniqueId, facturatieWeekNummer, selected_invoice.Factuurnummer, selected_invoice.Project_number);
      this.jsonIdDatabase.push(factuurUniqueId);
      }
    }
  }



  BalieLogUpdate(balielog: any, factuurUniqueId: any , weekNummer: any, afas_factuurNummer: any, afas_projectNumber: any) {

    // SET TOTAL BEDRAG TO 0
    let totaalBedrag = 0;

    // COUNT TO GET THE TOTAL FACTUURBEDRAG
    for (const element of balielog.FbDirectInvoice.Element.Objects[0].FbDirectInvoiceLines.Element) {
      totaalBedrag += element.Fields.QuUn * element.Fields.Upri;

    }

    // CREEATE JSON PAYLOAD FOR LOG DATABASE
    const dataToSend = {
      KnCustomK03: {
        Element: {
          Fields: {
            U640E232841E9567517FE2A9D48C1A99E: factuurUniqueId,
            U9B4C6A3141AC0F421636BDBED7E0D432: weekNummer,
            U9943903A45043EDDA24F4B8F13734B45: afas_factuurNummer,
            UE5DD6409493A0621E4CE22BFCD1DD062: totaalBedrag,
            UA8E8AE0244704AA98A5FB8B0EB07D4B0: balielog,
            UD1A79300450BADDFA65A43AE632ECF74: afas_projectNumber,
            Da: this.datePipe.transform(this.currentDate, 'yyyy-MM-dd')
          }
        }
      }
    };

    // PRINT JSON PAYLOAD FOR LOG DATABASE
    console.log(dataToSend);

    // SEND DAGTA TO LOG DATABASE IN AFAS
    this.httpClient.post(this.twdRestUrl, JSON.stringify(dataToSend), this.httpOptionsRest)
        .pipe(
          map( (response) => {
              this.responseCounter += 1;

              // AFTER SENDING DATA TO JSON LOG IN AFAS PRINT DATA TO EXCELL SHEET
              if (this.jsonIdDatabase.length === this.responseCounter) {
                this.openDialog(this.jsonIdDatabase, weekNummer);
                }
              }
          ),
          catchError(this.handleError<any>('getheroes', weekNummer))
        )
        .subscribe();
    }

  openDialog(factuurId: any, weekNummer: any) {

    // GET FUNCTION FOR LOG DATA IN AFAS LOGBOOK FILTERED BY WEEKNUMBER
    this.httpClient.get<any>(this.afasLogBaliedirectUrl + weekNummer + '&operatortypes=1', this.httpOptionsRest)
    .pipe(
      map( response => {
        console.log('this is what you get from the log', response.rows);

        // CREATE EXCELL FROM LOG DATA TO CHECK
        this.exportDataToExcell(response.rows);
      })
    )
    .subscribe();
  }

  // EXPORT JSON DATA TO EXCELL SHEET FOR CHECK
  exportDataToExcell(newFacturenArray: any) {
    const fileName = 'facturen_to_check.xlsx';
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(newFacturenArray);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'facturen_to_check');

    XLSX.writeFile(wb, fileName);
  }

  // FUNCTION CHECK IF THEY ARE DUPLICATE LINES
  private hasDuplicates(array: []) {
      return (new Set(array)).size !== array.length;
  }

  // UPDATE FACTUURSTATUS ALS BALIEDIRECT GELUKT IS
  private baliedirectVoltooid(Administratie: number, Factuurnummer: string) {
    const update_factuur = {
      FiInvoice: {
        Element: {
          Fields: {
            UnId: Administratie,
            InId: Factuurnummer,
            U1504586C48EDCB5DD9B31781734D0D5F: true,
            UCA040EF24F700697C5EEC0B4D00D0C7F: this.currentDate,
            }
          }
        }
      };

    // UPDATE CONNECTOR FACTUURSTATUS
    this.httpClient.put(this.href_update, JSON.stringify(update_factuur), this.httpOptions2)
    .pipe( // FOUTMELDINGEN WEERGEVEN
      catchError(this.handleError<any>('getheroes', Factuurnummer))
      )
    .subscribe();
  }

  private handleError<T>(operation = 'operation', Factnr: string, result?: T) {
    return (error: any): Observable<T> => {
      console.log(error.error.externalMessage);
      console.log(error, 'er gaat iets niet goed jongens');
      this.Notification_Service.sendSnack(Factnr + ' mislukt: ' + error.error.externalMessage , 5000);

      return of(result as T);
    };
    }
}

@Component({
  selector: 'facturenDialog-dialog',
  templateUrl: 'facturenDialog-dialog.html',
})


export class facturenDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}
}
