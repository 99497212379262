import { Component, OnInit } from '@angular/core';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import {AuthService} from '../../auth.service';
import {AppComponent} from '../../app.component';

@Component({
  selector: 'app-financeapp',
  templateUrl: './financeapp.component.html',
  styleUrls: ['./financeapp.component.css']
})
export class FinanceappComponent implements OnInit {

  constructor(
    public authService: AuthService,
    public appComponent: AppComponent
  ) { }

  public loggedInUser = sessionStorage.userEmail;

  // NOTE: this is the list for access to the workshop app admin panel. To give access add email to list
  public authenticatedUsers = this.appComponent.dashboardAuthentication.FinanceAppAuth;

  ngOnInit() {
  }

}
