// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  text: ' - Dev',
  version: require('../../package.json').version,
  redirectUri: 'https://dashboard-dev.twd.nl',

  // AFAS Token
  token: 'AfasToken PHRva2VuPjx2ZXJzaW9uPjE8L3ZlcnNpb24+PGRhdGE+NkRFQUNCOUVFNTBFNDlGRjk2MzNFRTlDRjREQzA4MTA1OUNGRjJERjQ0NUQ3REU5NkYzQTRBOTIzMkM4MjFENjwvZGF0YT48L3Rva2VuPg==',

  // GET AFAS BALIEDIRECT LOG FILTERED ON CURRENT WEEK
  afasLogBaliedirectUrl : 'https://87674.rest.afas.online/ProfitRestServices/connectors/TWD_Rest_Baliedirect_Log?filterfieldids=invoiceWeek&filtervalues=',

  // GET Financial Mutation DATA
  get_FinMut_url : 'https://87674.rest.afas.online/ProfitRestServices/connectors/TWD_Financiele_mutaties',

  // Get phone list data
  get_PhoneList: 'https://87674.rest.afas.online/ProfitRestServices/connectors/TWD-PhoneList?filterfieldids=initials&filtervalues=%5Bis%20niet%20leeg%5D&operatortypes=9&skip=0&take=500',
  updatePhoneList: 'https://87674.rest.afas.online/ProfitRestServices/connectors/KnCustomK01',

  // Mobile Telephony list data
  getMobileTelephonyList: 'https://87674.rest.afas.online/ProfitRestServices/connectors/TWD_Mobile_Telephony?skip=0&take=500',
  updateMobileTelephonyList: 'https://87674.rest.afas.online/ProfitRestServices/connectors/KnCustomK05',
  updateEmployeeNumberHr: 'https://87674.rest.afas.online/ProfitRestServices/connectors/KnPerson',

  // Hardware List Data
  getHardwareList: 'https://87674.rest.afas.online/ProfitRestServices/connectors/TWD_Hardware_List?skip=0&take=500',
  updateHardwareList: 'https://87674.rest.afas.online/ProfitRestServices/connectors/KnCustomK04',

  // AFAS employees
  get_EmployeesForPhoneList: 'https://87674.rest.afas.online/ProfitRestServices/connectors/TWD_Employees?skip=0&take=5000',

  // UPDATE AFAS DATA
  gen_HTML_regels_url: 'https://87674.rest.afas.online/ProfitRestServices/connectors/TWD_Intercomp_BalieDirect?filterfieldids=Factuurnummer&filtervalues=',
  gen_Fin_Mutaties_url: 'https://87674.rest.afas.online/ProfitRestServices/connectors/FiEntries',
  gen_Export_url: 'https://87674.rest.afas.online/ProfitRestServices/connectors/FiInvoice',
  gen_BalieDirect_url: 'https://87674.rest.afas.online/ProfitRestServices/connectors/FbDirectInvoice',
  update_status_url: 'https://87674.rest.afas.online/ProfitRestServices/connectors/FiInvoice',

  // UPDATE AFAS LOG
  facturatieWeekUrl : 'https://87674.rest.afas.online/ProfitRestServices/connectors/KnCustomK03',


  // //  NEW WORKSHOP CURSUS APP LINKS
  workshopsGetUrl: 'https://87674.rest.afas.online/ProfitRestServices/connectors/TWD_Workshops?skip=-1&take=-1&orderbyfieldids=workshopDate',
  workshopsGetAllUrl: 'https://87674.rest.afas.online/ProfitRestServices/connectors/RDI_Courses?skip=-1&take=-1',
  // tslint:disable-next-line:max-line-length
  workshopsGetFilteredEvents: 'https://87674.rest.afas.online/ProfitRestServices/connectors/TWD_Workshops?filterfieldids=code&filtervalues=',
  workshopsGetAttendeesUrl: 'https://87674.rest.afas.online/ProfitRestServices/connectors/TWD_Workshops_Attendees?skip=0&take=1000&filterfieldids=eventId&filtervalues=',
  workshopAddAttendeeUrl: 'https://87674.rest.afas.online/ProfitRestServices/connectors/KnCourseMember',
  workshopDeleteAttendeeUrl: 'https://87674.rest.afas.online/ProfitRestServices/connectors/KnCourseMember/KnCourseMember/@CrId,@CdId,BcCo/',
  workshopGetFilteredAttendeesUrl: 'https://87674.rest.afas.online/ProfitRestServices/connectors/TWD_Workshops_Attendees_New?filterfieldids=eventId&filtervalues=',
  workshopAttendanceUrl: 'https://87674.rest.afas.online/ProfitRestServices/connectors/KnCourseSessionMember',
  // tslint:disable-next-line:max-line-length
  // getEmployeesUrl: 'https://87674.rest.afas.online/ProfitRestServices/connectors/TWD_Employees_TWA_get_leader?filterfieldids=Zoeknaam&filtervalues=',
  getEmployeesUrl: 'https://87674.rest.afas.online/ProfitRestServices/connectors/WA_Employees?filterfieldids=id&filtervalues=',
  getContacts: 'https://87674.rest.afas.online/ProfitRestServices/connectors/TWD_Contacts?filterfieldids=userInternalId&filtervalues=',
  updateEmployeesUrl: 'https://87674.rest.afas.online/ProfitRestServices/connectors/KnCustomK07',
  updateAttendeesList: 'https://87674.rest.afas.online/ProfitRestServices/connectors/KnCustomLinkC01',

  getMeetingRoomsUrl: 'https://graph.microsoft.com/beta/me/findRooms',

  // solr link
  solrLink: 'https://solr-dev.twd.nl'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
