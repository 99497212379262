import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {observable, Observable, of, throwError} from 'rxjs';
import {HttpResponsePhonelist} from '../../../models/phonelist-interface';
import {catchError, tap} from 'rxjs/operators';
import {NotificationService} from '../../../notification-service/notification.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PhoneListService {

  private getPhoneListUrl = environment.get_PhoneList;
  private updatePhoneListUrl = environment.updatePhoneList;


  private token = environment.token;
  private httpOptions = {
    headers: new HttpHeaders({
      'content-type': 'application/json',
      Authorization: this.token
    }),
  };

  constructor(private httpClient: HttpClient,
              private notificationService: NotificationService,
  ) { }

  // get phone list data from afas
  getPhoneListItems(): Observable<HttpResponsePhonelist> {
    return this.httpClient.get<HttpResponsePhonelist>(this.getPhoneListUrl, this.httpOptions);
  }

  // update phonelist data in afas
  updatePhoneListItem(info: any) {

    const phoneListData = {
      KnCustomK01: {
        Element: {
          Fields: {
            SqNo: info.sequenceNumber,
            U34CE846B4F17D147982979ADA96F7A22: info.phoneNumber,
            U294BA022483427E5E1DE5C9B1C8E9814: info.initials,
            U482FA5194A5A05233F53FDA6C3CD54D4: info.pcNumber,
            UDA9EAA3745DE7A64E8748CB6B6E9CEF1: info.firstName,
            UE6BC47B340E27BD13242B2BE8CF13317: info.workMobile,
            U2CC0AB514337F496CCC83296EDF3ADFF: info.lastName
          }
        }
      }
    };

    console.log('data to update phonelist: ', phoneListData);
    return this.httpClient.put(this.updatePhoneListUrl, JSON.stringify(phoneListData), this.httpOptions)
      .subscribe(
        data => this.notificationService.success('successfully updated'),
        error => this.notificationService.error('update failed: ' + error.error.externalMessage),
      );
  }

  // add new phone to list in afas
  addPhoneListItem(info: any) {

    const phoneListData = {
      KnCustomK01: {
        Element: {
          Fields: {
            Da: '2020-01-01',
            U34CE846B4F17D147982979ADA96F7A22: info.phoneNumber,
            U294BA022483427E5E1DE5C9B1C8E9814: info.initials,
            U482FA5194A5A05233F53FDA6C3CD54D4: info.pcNumber,
            UDA9EAA3745DE7A64E8748CB6B6E9CEF1: info.firstName,
            UE6BC47B340E27BD13242B2BE8CF13317: info.workMobile,
            U2CC0AB514337F496CCC83296EDF3ADFF: info.lastName
          }
        }
      }
    };

    console.log('data to update phonelist: ', phoneListData);
    this.httpClient
      .post(this.updatePhoneListUrl, JSON.stringify(phoneListData), this.httpOptions)
      .subscribe(
        data => this.notificationService.success('successfully updated'),
        error => this.notificationService.error('update failed: ' + error.error.externalMessage),
      );
  }

}
