import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MobileTelephonyService} from '../mobile-telephony.service';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-mobile-telephony-dialog',
  templateUrl: './mobile-telephony-dialog.component.html',
  styleUrls: ['./mobile-telephony-dialog.component.scss']
})
export class MobileTelephonyDialogComponent implements OnInit {
  mobileDetailForm: FormGroup;
  true = 'true';
  false = 'False';

  panelOpenState = false;
  openedFromAddBtn: boolean;
  submitted = false;

  location: any = ['NL', 'UK', 'GR', 'BM'];

  constructor(
    private mobileTelephonyService: MobileTelephonyService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder) {

    this.mobileDetailForm = this.fb.group({
      sequenceNumber: [data.mobile.Volgnummer],
      owner: [data.mobile.owner, [Validators.required, Validators.maxLength(3)]],
      phoneNum: [data.mobile.phoneNum, [Validators.required, Validators.maxLength(13)]],
      simNum: [data.mobile.simNum, [Validators.required]],
      pukCode: [data.mobile.pukCode, [Validators.required]],
      phoneType: data.mobile.phoneType,
      mb: data.mobile.mb,
      expDateAbb: [data.mobile.expDateAbb, [Validators.required]],
      imeiNum: [data.mobile.imeiNum, [Validators.maxLength(15)]],
      imeiNumberTwo: [data.mobile.imeiNumberTwo, [Validators.maxLength(15)]],
      serialNum: data.mobile.serialNum,
      notes: data.mobile.notes,
      twdLocation: [data.mobile.twdLocation, [Validators.required]],
      compensation: [data.mobile.compensation, [Validators.required]],
      compensationAmount: data.mobile.compensationAmount,
      compensationDate: data.mobile.compensationDate,
      eSim: data.mobile.eSim,
      ePin: data.mobile.ePin,
    });
  }

  ngOnInit(): void {
    console.log('mobileTelephonyDialogData: ', this.data);
    this.openedFromAddBtn = this.data.openedFromAdd;
    console.log('opened from add btn? ', this.openedFromAddBtn);
  }

  get f() { return this.mobileDetailForm.controls; }

  onSubmit(): void {
    console.log('value for update: ', this.mobileDetailForm.value);
    this.submitted = true;

    if (this.mobileDetailForm.invalid === true) {
      return;
    } else if (this.openedFromAddBtn === true && this.mobileDetailForm.invalid === false) {
      this.mobileTelephonyService.addMobileListItem(this.mobileDetailForm.value);
      this.mobileTelephonyService.updateHrField(this.data.mobile.ownerId, this.data.mobile.phoneNum);
    } else if (this.openedFromAddBtn === false && this.mobileDetailForm.invalid === false) {
      this.mobileTelephonyService.updateMobileListItems(this.mobileDetailForm.value);
      this.mobileTelephonyService.updateHrField(this.data.mobile.ownerId, this.data.mobile.phoneNum);
    }
  }

}

// TODO: Make compensation date not required
