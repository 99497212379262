import {Injectable, OnInit} from '@angular/core';
import {MsalService} from '@azure/msal-angular';
import {Client} from '@microsoft/microsoft-graph-client';
import {AlertsService} from './alerts/alerts.service';
import {OAuthSettings} from '../oauth';
import {User} from './models/user';
import { MsalBroadcastService } from '@azure/msal-angular';
import { EventMessage, EventType } from '@azure/msal-browser';
import { filter, Subject, takeUntil } from 'rxjs';
import { NotificationService } from './notification-service/notification.service';
import {GraphService} from './graph.service';
import {AfasDataService} from './afas-data.service';
import {environment} from '../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnInit {

  public user: User;
  public broadcastingMessages: any = [];
  isIframe = false;
  userLogged = false;
  private readonly destroying$ = new Subject<void>();



  // used to avoid reload during acquireTokenSilent() because of hidden iframe
  ngOnInit() {
    this.isIframe = window !== window.parent && !window.opener;
  }

  constructor(
    private authService: MsalService,
    private alertsService: AlertsService,
    private broadcastService: MsalBroadcastService,
    private notificationService: NotificationService,
    private graphService: GraphService,
    private afasDataService: AfasDataService,
    ) {}

  // Prompt the user to sign in and
  // grant consent to the requested permission scopes
  async signIn(): Promise<void> {
    console.log('singing in !!');
    await this.acquireSignInSilent();
  }

  setLoginDisplay() {
    this.userLogged = this.authService.instance.getAllAccounts().length > 0;
  }

  acquireSignInSilent() {
    // silent get all logged in user accounts
    const activeAccount = this.authService.instance.getAllAccounts();
    // set access token request items
    const accessTokenRequest = {
      scopes: ['user.read'],
      account: activeAccount[0],
      redirectUri: environment.redirectUri
    };
    // get the sso token silent
    this.authService.instance.acquireTokenSilent(accessTokenRequest).then(
      (res) =>  {
        console.log(res);
        sessionStorage.setItem('accessToken', res.accessToken);
        sessionStorage.setItem('userEmail', res.account.username);
        this.setLoginDisplay();
      }
    ).catch((error: any) => {
      // if unsuccessfull popup login screen
      this.authService.loginPopup()
        .subscribe({
          next: (result) => {
            console.log(result);
            sessionStorage.setItem('userEmail', result.account.username);
            sessionStorage.setItem('accessToken', result.accessToken);
            this.graphService.getUser().then(() => this.afasDataService.getAfasData(sessionStorage.userAbb));
            this.setLoginDisplay();
          },
          error: (popupError) => this.notificationService.error(popupError),
          complete: () => this.graphService.getUser().then(() => this.afasDataService.getAfasData(sessionStorage.userAbb))

        });
    });
  }

  // Sign out
  signOut(): void {
    this.authService.logout();
    this.user = null;
    this.userLogged = false;
  }

  // Silently request an access token
  async getAccessToken(): Promise<string> {
    let token = '';
    this.broadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
        takeUntil(this.destroying$)
      )
      .subscribe((result: EventMessage) => {
        token = JSON.stringify(result);
      });
    return token;
  }
}
