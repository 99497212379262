import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-snack',
  template: `
    <span>
	  {{data.message}}
    </span>
    <button mat-icon-button (click)="destroy()">
      <mat-icon>close</mat-icon>
    </button>
  `,
  styleUrls: ['./snack.component.scss'],
})
export class SnackComponent implements OnInit {
  @Input() data: any;
  @Output() close = new EventEmitter<void>();
  constructor() {}
  destroy() {
    this.close.emit();
  }
  ngOnInit(): void {}
}
