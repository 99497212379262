import {DatePipe} from '@angular/common';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../auth.service';
import * as moment from 'moment';
// import {Workshop_details_dialogComponent} from './workshop_details_dialog/workshop_details_dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {Workshop_attendees_dialogComponent} from './workshop_attendees_dialog/workshop_attendees_dialog.component';
import {DateTimeTimeZone} from '../../models/event';
import {AlertsService} from 'src/app/alerts/alerts.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {GraphService} from 'src/app/graph.service';
import {NotificationService} from '../../notification-service/notification.service';
import authenticationJson from '../../../assets/authentication/athentication.json';
import {GetWorkshopDataService} from './getWorkshopData.service';
import {ReloadPageService} from '../../page-reload/reload-page.service';
import {MailService} from '../../mail-service/mail.service';
import {AddToCalendarService} from './add-to-calendar.service';
import {formatDate, CommonModule} from '@angular/common';
import {environment} from '../../../environments/environment';
import {AppComponent} from '../../app.component';

@Component({
  selector: 'app-workshopapp_v2',
  templateUrl: './workshopapp_v2.component.html',
  styleUrls: ['./workshopapp_v2.component.css'],
})
export class Workshopapp_v2Component implements OnInit {
  constructor(
    private httpClient: HttpClient,
    private datePipe: DatePipe,
    public authService: AuthService,
    public dialog: MatDialog,
    public alertService: AlertsService,
    public snackBar: MatSnackBar,
    public graphService: GraphService,
    private workshopDataService: GetWorkshopDataService,
    private notificationService: NotificationService,
    private reloadPageService: ReloadPageService,
    private mailService: MailService,
    public addToCalendarService: AddToCalendarService,
    public appComponent: AppComponent
  ) {}

  // create empty list for workshops
  public workshopData: any = [];
  public workshopAttendeesData: any = [];
  public myWorkshopsData: any = [];

  // set empty list for events that are marked recap events
  public recapEvent = [];

  // store logged in user and user abbreviation
  public loggedInUser = sessionStorage.userEmail;
  public loggedInUserAbb = sessionStorage.userAbb;

  // NOTE: this is the list for access to the workshop app admin panel. To give access add email to list
  public authenticatedForAdmin = this.appComponent.dashboardAuthentication.WorkshopAdminPanelAuth;

  // set counter for number of attendees to 0
  step = 0;

  // set isloading state to false
  public isLoading = false;

  // columns for admin table
  displayedColumns: string[] = [
    'date',
    'subject',
    'practical',
    'recap',
    'presenter',
    'attendees',
    // 'edit',
  ];

  // columns for manager table
  displayedColumnsManager: string[] = [
    'date',
    'subject',
    // 'practical',
    // 'recap',
    // 'presenter',
    'attendees',
    // 'edit',
  ];

  httpOptions = {
    headers: new HttpHeaders({
      'content-type': 'application/json',
      Authorization: environment.token,
    }),
  };

  ngOnInit() {
    this.GetWorkshopsOnload();
    console.log('logged in user = ', this.loggedInUser);
  }

  GetWorkshopsOnload() {
    // enable loader
    this.isLoading = true;
    this.workshopDataService.GetAfasWorkshops().subscribe((result) => {
      this.workshopData = result.rows;
      console.log('workshopdata: ', this.workshopData);
      // disable loader
      this.isLoading = false;
    });

    this.workshopDataService.GetAfasWorkshopAttendees().subscribe((result) => {
      this.workshopAttendeesData = result.rows;
      console.log('attendeesdata: ', this.workshopAttendeesData);
    });

  }

  // // get a list of meetingrooms from azure
  // GetMeetingRooms() {
  //   return this.graphService.getMeetingRooms();
  // }

  // openDialog(event: any) {
  //   const dialogRef = this.dialog.open(Workshop_details_dialogComponent, {
  //     width: '1000px',
  //     data: {
  //       workshop: event,
  //     },
  //   });
  //   console.log(event);
  //
  //   dialogRef.afterClosed().subscribe((result) => {
  //     console.log(`Dialog result: ${result}`);
  //   });
  // }

  viewAttendees(info: any) {
    const dialogRef = this.dialog.open(Workshop_attendees_dialogComponent, {
      width: '1000px',
      data: {
        workshopId: info.workshopId,
      },
    });
  }

  //  // boolean to check if workshop is being added or edited
  //  //  boolean = true
  // addWorkshop(openedFromAdd: boolean) {
  //   console.log('workshop added');
  //   const dialogRef = this.dialog.open(Workshop_details_dialogComponent, {
  //     width: '1000px',
  //     data: {
  //       workshop: '',
  //       openedFromAdd,
  //     },
  //   });
  //
  //   dialogRef.afterClosed().subscribe((result) => {
  //     console.log(`Filled Data: ${result}`);
  //   });
  // }

  // editWorkshop(info: any) {
  //   console.log(info);
  //   const dialogRef = this.dialog.open(Workshop_details_dialogComponent, {
  //     width: '1000px',
  //     data: {
  //       workshop: info,
  //     },
  //   });
  //
  //   dialogRef.afterClosed().subscribe((result) => {
  //     console.log(`Filled Data: ${result}`);
  //   });
  // }

  // create teams link for workshop
  createTeamsLink(info: any) {
    console.log('creating teams link: ', info);
    const formattedStartDate = this.formatDate(info.startDate) + 'T' + info.startTime;
    const formattedEndDate = this.formatDate(info.endDate) + 'T' + info.endTime;
    // create body for generating teams meeting in calendar of workshops
    const requestBOdy = {
      subject: info.subject,
        body: {
            contentType: 'HTML',
  // NOTE: hier komt de data voor de outlook calender
            content: info.description
        },
        start: {
            dateTime: formattedStartDate,
            timeZone: 'Europe/Paris'
        },
        end: {
            dateTime: formattedEndDate,
            timeZone: 'Europe/Paris'
        },
        attendees: [
          {
            emailAddress: {
              address: info.speaker + '@twd.nl',
            },
          }
        ],

        isOnlineMeeting: true,
        onlineMeetingProvider: 'teamsForBusiness'
    };

    console.log('creating body for workshop calendar: ', requestBOdy);
    // get Teams Meeting URL
    return this.graphService.createEvents(requestBOdy);

  }

  formatDateTimeTimeZone(dateTime: DateTimeTimeZone): string {
    try {
      return moment.tz(dateTime.dateTime, dateTime.timeZone).format();
    } catch (error) {
      this.alertService.add(
        'DateTimeTimeZone conversion error',
        JSON.stringify(error)
      );
    }
  }

  signOutOfWorkshop(event) {
    this.isLoading = true;
    // console.log(event);

    // send mail to sign out of event
    // this.mailService.sendMail(event);

    const deleteUrl = environment.workshopDeleteAttendeeUrl +
      `${event.eventId},${sessionStorage.userExternalId},${sessionStorage.userInternalId}`;

    console.log(deleteUrl);
    // send data to afas
    this.httpClient.delete(deleteUrl , this.httpOptions)
    .subscribe(
      data => this.notificationService.success('successfully updated'),
      error => this.notificationService.error('update failed: ' + error.error.externalMessage),
      () => this.realoadPage(),
    );
  }

  joinWorkShop(event) {
    // check if the user ABB is correct
    this.isLoading = true;
    console.log(event);
    // generate json payload for afas workshop vrije bestand
    const dataForUpdate = {
      KnCourseMember: {
        Element: {
          '@CrId': event.workshopId, // event ID
          '@CdId': sessionStorage.userExternalId,  // User External Contact ID
          Fields: {
            BcCo: sessionStorage.userInternalId, // User Internal ID
            SuDa: formatDate(new Date(), 'yyyy-MM-dd', 'en'), // Date of today
            Invo: false
          }
        }
      }
    };

    console.log(dataForUpdate);

    // send data to afas
    this.httpClient.post(environment.workshopAddAttendeeUrl, JSON.stringify(dataForUpdate), this.httpOptions)
      .subscribe(
        data => this.notificationService.success('you have subscribed for' + event.workshopTitle),
        error => this.notificationService.error('failed: ' + error.error.externalMessage),
        () => this.reloadPageSignIn(event),
      );

  }

  // joinWorkShop(event) {
  //   this.addToCalendarService.addToCal(event);
  // }


  realoadPage() {
    this.isLoading = false;
    this.reloadPageService.reloadCurrentRoute();
  }

  reloadPageSignIn(event) {
    // add workshop to outlook calendar after successfully subscribing
    this.addToCalendarService.addToCal(event);
    this.isLoading = false;
    this.reloadPageService.reloadCurrentRoute();
  }

  // format date to YYYY-MM-DD
  formatDate(currentDate) {
    let d = new Date(currentDate),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) {
        month = '0' + month;
    }
    if (day.length < 2) {
        day = '0' + day;
    }

    return [year, month, day].join('-');
  }


}





