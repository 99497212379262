import { Component, OnInit } from '@angular/core';
import authenticationJson from '../../../assets/authentication/athentication.json';
import {AuthService} from '../../auth.service';
import {AppComponent} from '../../app.component';

@Component({
  selector: 'app-ict',
  templateUrl: './ict.component.html',
  styleUrls: ['./ict.component.css']
})
export class IctComponent implements OnInit {

  constructor(
    public authService: AuthService,
    public appComponent: AppComponent
  ) { }

  public authenticatedUsers = this.appComponent.dashboardAuthentication.IctDashboardAuth;
  public loggedInUser = sessionStorage.userEmail;

  ngOnInit(): void {
    console.log(this.authenticatedUsers);
  }
}
