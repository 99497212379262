// tslint:disable: max-line-length
import { Injectable } from '@angular/core';
import {FactuurModel, HttpResponseFacturen} from '../facturen_overzicht/main_models';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { NotificationService } from '../../../notification-service/notification.service';
import { catchError, map } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import {environment} from '../../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class GenFinMutatiesService {


constructor(
  private httpClient: HttpClient,
  private notificationService: NotificationService,
  private datePipe: DatePipe,
) { }

// TEST TOKEN
private token = environment.token;
private href = environment.gen_Fin_Mutaties_url;


// TEST TOKEN
private href2 = environment.get_FinMut_url;

httpOptions = {
  headers: new HttpHeaders({ 'content-type': 'application/json', 'Authorization': this.token })
};
responseStatus: number;

// Update connector Facturen
update_invoices_in_AFAS(selection: FactuurModel[]) {

  // GET DATE AFAS FORMAT
  const currentDate = new Date();
  this.datePipe.transform(currentDate, 'yyyy-MM-dd');

  // FOR LOOP OM DE PAYLOAD TE EGNEREREN VOOR DE FINANCIELE MUTATIES
  for (const prop of selection) {

    // TO DO FORMAT DIGITS TO 02
    const periode = prop.Periode;

    const dataForUpdate = {
      FiEntryPar: {
      Element: {
          Fields: {
          Year: prop.Boekjaar,
          Peri: prop.Periode,
          UnId: 2,
          JoCo: '14',
          AuNu: true
          },
          Objects: [
          {
              FiEntries: {
              Element: [
                  {
                  Fields: {
                      VaAs: '3',
                      AcNr: prop.Inkooprelatie,
                      EnDa: prop.DatumUpdateCon,
                      BpDa: prop.Factuurdatum,
                      BpNr: prop.Factuurnummer,
                      Ds: prop.Omschrijving_2,
                      AmCr: prop.Factuurtotaal,
                  }
                  },
                  {
                  Fields: {
                      VaAs: '1',
                      AcNr: '6000',
                      EnDa: prop.DatumUpdateCon,
                      BpDa: prop.Factuurdatum,
                      BpNr: prop.Factuurnummer,
                      Ds: prop.Omschrijving_2,
                      AmDe: prop.Factuurtotaal,
                      AmCr: '',
                      VaId: '50',
                  }
                  }
              ]
              }
          }
          ]
      }
      }
      };



  // UPDATE CONNECTOR FOR FINANCIELE MUTATIES
    this.httpClient.post(this.href, JSON.stringify(dataForUpdate), this.httpOptions)
    .pipe( // HERE COMES THE
      map( response => {
        this.notificationService.sendSnack('Fin. Mutatie voor ' + prop.Factuurnummer + ' succesvol voltooid!', 5000);
      }),
      catchError(this.handleError<any>('getHeroes', prop.Factuurnummer))
      )
    .subscribe();

}

}

// Get Facturen
GetFinanMutations(): Observable<HttpResponseFacturen> {
  return this.httpClient.get<HttpResponseFacturen>(this.href2, this.httpOptions);
}


private handleError<T>(operation = 'operation', FactNr: string, result?: T) {
  return (error: any): Observable<T> => {

    // TODO: send the error to remote logging infrastructure

    // TODO: better job of transforming error for user consumption
    this.notificationService.sendSnack('Fin. Mutatie voor ' + FactNr + ' mislukt: ' + error.error.externalMessage, 5000);

    // Let the app keep running by returning an empty result.
    return of(result as T);
  };
}



}
