import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GetSearchFilesService {
  public token = environment.token;

  public httpOptions = {
    headers: new HttpHeaders({
      'content-type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) {}

  get_twdsearch_files(
    solrLink,
    pagesize,
    pageindex,
    query,
    range,
    involved,
    docType,
    docLocation,

  ): Observable<any> {
    // search in file name
    // https://solr.twd.nl/solr/twd_collection/select?fq=doc_name + search + &indent=true&q.op=OR&q=*%3A*
    // search in file content
    // https://solr.twd.nl/solr/twd_collection/select?fq=__text__ + search + &indent=true&q.op=OR&q=*%3A*
    const apiCall = `${solrLink}twd_collection/select?${range}${involved}${docType}${docLocation}${query}&rows=${pagesize}&start=${pageindex * pagesize}&sort=doc_views%20desc&facet.field=doc_location&facet=on&q=*:*&wt=json`;
    console.log(`generated api call link: ${apiCall}`);
    return this.http.get<any>(apiCall, this.httpOptions);
  }

  get_projects(solrLink, pagesize, pageindex, query): Observable<any> {
    const apiCall = `${solrLink}twd_projects/select?${query}&rows=${pagesize}&start=${pageindex * pagesize}&q=*:*&q.op=AND&wt=json`;
    console.log(`generate api call link for projects: ${apiCall}`);
    return this.http.get<any>(apiCall, this.httpOptions);
  }

  get_twdsearch_files_onload(solrLink): Observable<any> {
    return this.http.get<any>(
      solrLink + 'twd_collection/select?q=*:*&sort=doc_views desc&facet.field=doc_location&facet=on&wt=json', this.httpOptions);
  }

  get_twdsearch_files_onTab(
    solrLink,
    pagesize,
    pageindex,
    query,
    docType,
    docLocation
  ): Observable<any> {
    // tslint:disable-next-line:max-line-length
    const apiCall = `${solrLink}twd_collection/select?${docType}${docLocation}${query}&rows=${pagesize}&start=${pageindex * pagesize}&sort=doc_views%20desc&facet.field=doc_location&facet=on&q=*:*&wt=json`;
    console.log(apiCall);
    return this.http.get<any>(apiCall, this.httpOptions);
  }

  get_twdsearch_names_onload(solrLink): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'content-type': 'application/json',
      }),
    };
    return this.http.get<any>(
      solrLink + 'twd_collection/select?facet.field=twda_names&facet.query=*%3A*&facet=on&q=*%3A*&wt=json',
      httpOptions
    );
  }

  get_twdsearch_filetypes_onload(solrLink): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'content-type': 'application/json',
      }),
    };
    return this.http.get<any>(
      solrLink + 'twd_collection/select?facet.field=doc_type&facet.query=*%3A*&facet=on&q=*%3A*&wt=json',
      httpOptions
    );
  }

  get_projects_onload(solrLink): Observable<any> {
    return this.http.get<any>(
      solrLink + 'twd_projects/select?q=*:*&wt=json', this.httpOptions);
  }

  get_projects_onTab(
    solrLink,
    pagesize,
    pageindex,
    query,
  ): Observable<any> {
    // tslint:disable-next-line:max-line-length
    const apiCall = `${solrLink}twd_projects/select?${query}&rows=${pagesize}&start=${pageindex * pagesize}&q=*:*&wt=json`;
    console.log(apiCall);
    return this.http.get<any>(apiCall, this.httpOptions);
  }

  update_twaviews(solrLink, id) {
    const data = JSON.stringify([{
      id: id,
      doc_views: {inc: 1}
    }]);

    console.log(`adding view: ${id}`);
    console.log(`body: ${data}`);

    this.http.post(solrLink + 'twd_collection/update?commitWithin=1000&overwrite=true&wt=json', data, this.httpOptions, )
    .subscribe();
  }


}

