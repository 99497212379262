<div class="row">
  <div class="col-10">
    <mat-form-field>
      <mat-label>Search...</mat-label>
      <input matInput (keyup)="applyFilter($event)">

    </mat-form-field>
  </div>
  <div class="col-2">
    <button mat-button class="twd_btn" (click)="openMobileListDialog('', true)">Add</button>
  </div>
</div>




<table mat-table [dataSource]="tableDataSource" matSort class="mat-elevation-z8">
<!--  Owner colimn-->
  <ng-container matColumnDef="owner">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Owner </th>
    <td mat-cell *matCellDef="let element">{{element.owner}}</td>
  </ng-container>

  <ng-container matColumnDef="phoneType">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Phone Type </th>
    <td mat-cell *matCellDef="let element">{{element.phoneType}}</td>
  </ng-container>

  <ng-container matColumnDef="phoneNum">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Phone Number </th>
    <td mat-cell *matCellDef="let element">{{element.phoneNum}}</td>
  </ng-container>

  <ng-container matColumnDef="expDateAbb">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Exp. Date Plan </th>
    <td mat-cell *matCellDef="let element">{{element.expDateAbb | date:"dd/MM/yyyy" }}</td>
  </ng-container>

  <ng-container matColumnDef="edit">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <button mat-raised-button class="twd_btn" (click)="openMobileListDialog(element, false)">Edit</button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
