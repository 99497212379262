import {Component, OnInit} from '@angular/core';
import {SnackService} from './snack.service';
import {slide} from './snack.animations';
@Component({
  selector: 'app-snack-container',
  template: `
  <div *ngFor="let snack of service.$stack | async; let i = index"
  [@slide]="'flyIn'"
  class="mat-snack-bar-container snack-container-size">
  <app-snack
    [data]="snack"
    (close)="destroy(i)">
  </app-snack>
  </div>`,
  styles: [':host { display:flex; margin: 16px; flex-direction: column; place-content: end space-between; position:fixed; bottom: 0; right: 0;} .snack-container-size {width: 400px; margin-top: 16px }'],
  animations: [slide],
})
export class SnackContainerComponent {
  constructor(public service: SnackService) {}
  destroy(index: number) {
    this.service.close(index);
  }
}
