import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {HttpResponseMobileTelephony} from '../../../models/mobileTelephony';
import {NotificationService} from '../../../notification-service/notification.service';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MobileTelephonyService {

  private getMobileListUrl = environment.getMobileTelephonyList;
  private updateMobileListUrl = environment.updateMobileTelephonyList;
  private updateEmployeeNumberHrUrl = environment.updateEmployeeNumberHr;
  private token = environment.token;
  private httpOptions = {
    headers: new HttpHeaders({
      'content-type': 'application/json',
      Authorization: this.token
    }),
  };

  constructor(private httpClient: HttpClient,
              private notificationService: NotificationService,
  ) { }

  getMobileListItems(): Observable<HttpResponseMobileTelephony> {
    return this.httpClient.get<HttpResponseMobileTelephony>(this.getMobileListUrl, this.httpOptions);
  }

  // update mobile data in afas
  updateMobileListItems(info: any) {
    const mobileListData = {
      KnCustomK05: {
        Element: {
          Fields: {
            SqNo: info.sequenceNumber,
            Da: '2021-09-07',
            U8972B3B14D0E5567E994128555C0AE9A: info.owner,
            U34C0A7A948F310192CF3038D73249A93: info.phoneNum,
            U434ADFBB4D211F43356DF793CCE6D59C: info.simNum,
            U41958ABA434AF5DF347D7ABC42102EA9: info.pukCode,
            U4815C523485B45D47F01128FBD09D116: info.phoneType,
            // MB's in numbers
            UA881BEBA46C551C5ECEA34A7791032C1: info.mb,
            // price per month
            U9070906049F6EAD3A6A1D3A94DF5017C: info.pricePerMonth,
            U487CF8854C8455917F9AC8C0996EB27F: info.expDateAbb,
            UE721FD514E6F13602E83C1907E6520BA: info.imeiNum,
            U7692A9E94DF40971274CA3B8D349EE8D: info.imeiNumberTwo,
            U848DC9304CE720B965E886BAB00CE781: info.serialNum,
            U77D68E8E4FC44E37B1A065B8E5815CB0: info.notes,
            UA7486D89483A67964AF766B63A18A75E: info.twdLocation,
            // compensation received boolean
            U49F7E8C141ADD68C1D51F1AE9F6957BD: info.compensation,
            // compensation amount num
            UBF714750460DB57774E084B62E98A52F: info.compensationAmount,
            U98BB6A42472517F7306338807E16D845: info.compensationDate,
            // esim true or false
            U62867CCE41375726100D589A71B5BBD8: info.eSim,
            // epin numerical
            U72A5E9344FF3FD44D30B00991AC12FC9: info.ePin
          }
        }
      }
    };

    console.log('data to update mobileList: ', mobileListData);
    this.httpClient
      .put(this.updateMobileListUrl, JSON.stringify(mobileListData), this.httpOptions)
      .subscribe(
        data => this.notificationService.success('Successfully updated'),
        error => this.notificationService.error('Update failed: ' + error.error.externalMessage),
      );
  }

//  add new mobile to list in afas
  addMobileListItem(info: any) {
    const mobileListData = {
      KnCustomK05: {
        Element: {
          Fields: {
            // TODO uncomment date so script works
            // Da: '2021-09-07',
            U8972B3B14D0E5567E994128555C0AE9A: info.owner,
            U34C0A7A948F310192CF3038D73249A93: info.phoneNum,
            U434ADFBB4D211F43356DF793CCE6D59C: info.simNum,
            U41958ABA434AF5DF347D7ABC42102EA9: info.pukCode,
            U4815C523485B45D47F01128FBD09D116: info.phoneType,
            // MB's in numbers
            UA881BEBA46C551C5ECEA34A7791032C1: info.mb,
            // price per month
            U9070906049F6EAD3A6A1D3A94DF5017C: info.pricePerMonth,
            U487CF8854C8455917F9AC8C0996EB27F: info.expDateAbb,
            UE721FD514E6F13602E83C1907E6520BA: info.imeiNum,
            U7692A9E94DF40971274CA3B8D349EE8D: info.imeiNumberTwo,
            U848DC9304CE720B965E886BAB00CE781: info.serialNum,
            U77D68E8E4FC44E37B1A065B8E5815CB0: info.notes,
            UA7486D89483A67964AF766B63A18A75E: info.twdLocation,
            // compensation received boolean
            U49F7E8C141ADD68C1D51F1AE9F6957BD: info.compensation,
            // compensation amount num
            UBF714750460DB57774E084B62E98A52F: info.compensationAmount,
            U98BB6A42472517F7306338807E16D845: info.compensationDate,
            // esim true or false
            U62867CCE41375726100D589A71B5BBD8: info.eSim,
            // epin numerical
            U72A5E9344FF3FD44D30B00991AC12FC9: info.ePin
          }
        }
      }
    };

    console.log('date to add mobileList: ', mobileListData);
    this.httpClient
      .post(this.updateMobileListUrl, JSON.stringify(mobileListData), this.httpOptions)
      .subscribe(
        data => this.notificationService.success('successfully updated'),
        error => this.notificationService.error('update failed: ' + error.error.externalMessage),
      );
  }

  // update the phone number in the HR overview
  updateHrField(ownerId, phoneNumber) {
    const hrData = {
      KnPerson: {
        Element: {
          Fields: {
            MatchPer: '0',
            BcCo: ownerId,
            MbNr: phoneNumber
          }
        }
      }
    };

    console.log(hrData);
    this.httpClient
      .put(this.updateEmployeeNumberHrUrl, JSON.stringify(hrData), this.httpOptions)
      .subscribe(
        data => this.notificationService.success('successfully updated in HR'),
        error => this.notificationService.error ('update failed: ' + error.error.externalMessage),
      );
  }

}

//TODO: if device has no owner get owner + id before update in HR
