<mat-grid-list cols="5" rowHeight="1.5:1" >
  <mat-grid-tile *ngFor="let skills of expertHubData"
                 [colspan]="skills.cols"
                 [rowspan]="skills.rows"
  >

    <a (click)="openDialog(skills.categories)">
      <img class="skillImage {{skills.id}}" src="/assets/experthub/{{skills.id}}.png" alt="{{skills.id}}" width="40%" >
      <p>{{skills.id}}</p>
    </a>
<!--    <button (click)="openDialog(skills)">{{skills.key}}</button>-->

  </mat-grid-tile>
</mat-grid-list>
