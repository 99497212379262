import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth.service';

@Component({
  selector: 'app-kbe-wiki',
  templateUrl: './kbe-wiki.component.html',
  styleUrls: ['./kbe-wiki.component.css']
})
export class KbeWikiComponent implements OnInit {

  constructor(
    public authService: AuthService
  ) { }

  ngOnInit() {
  }

}
