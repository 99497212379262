<div>
  <div class="cards-container">
    <div fxLayout="row wrap" fxLayoutGap="16px grid" style="width: 100%">
      <div fxFlex="16%" fxFlex.xs="80%" fxFlex.sm="33.3%" *ngFor="let user of ansysLicenseData">
        <mat-card class="example-card">
          <mat-card-header>
            <div mat-card-avatar class="example-header-image"></div>
            <mat-card-title>{{user.user}}</mat-card-title>
            <mat-card-subtitle>{{user.license}}</mat-card-subtitle>
            <mat-card-subtitle>{{user.up_time}}</mat-card-subtitle>
          </mat-card-header>
        </mat-card>
      </div>
    </div>
  </div>
</div>
