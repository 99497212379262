import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {HttpResponseHardwareList} from '../../../models/hardwareList';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HardwareListService {

  private getHardwareListUrl = environment.getHardwareList;
  private updateHardwareListUrl = environment.updateHardwareList;
  private token = environment.token;
  private httpOptions = {
    headers: new HttpHeaders({
      'content-type': 'application/json',
      Authorization: this.token
    }),
  };

  constructor(private httpClient: HttpClient) { }

  getHardwareListItems(): Observable<HttpResponseHardwareList> {
    return this.httpClient.get<HttpResponseHardwareList>(this.getHardwareListUrl, this.httpOptions);
  }

  // update hardware list in afas
  updateHardwareListItems(info: any) {
    const hardwareListData = {
      KnCustomK04: {
        Element: {
          Fields: {
            SqNo: info.seqNum,
            Da: '2021-09-15',
            UC54F5F9B42D4C30072BFD18D73FE8AB8: info.pcName,
            U314C17EE49D3898F82908194F628E5C4: info.pcModel,
            U37649814484AA5AB1371B7B3DDFA7FF1: info.processorType,
            UAE4CFEDD485E1BB9255E39897D5ED209: info.graphicsCard,
            U41641131449E27D81B163289B04FC450: info.serialKey,
            // clean installed ?
            UFED309314F895CF2871CA081D2A11697: true,

            U127432144327AD7E8562A2BF923AD05B: info.lastInstallDate,
            U3088E0C743050BC8FEFEE784BE2DF5DF: info.warrantyExDate,
            U7F55BC694ECEE2C73EA1A19894C4F4D0: info.purchaseDate,
            // excrypted with bitlocker?
            U22430A5645300B1AA800C3948238F64C: true,

            UF2045CFE44A5A521F2A935A1332914A8: info.addInfo,
            U76152D4449509E29E1064EB5C0050447: info.ssd,
            U67DC55804034541DAADE5E871F1141B1: info.ram,
            U2A2C04B54FEB6294715554AC869D89C0: info.location,
            U6CF658494F411DE6886568967A60E20C: info.productKey,
            UC359E6B84BF0FD5B9FDAA49B388BDF7A: info.owner
          }
        }
      }
    };

    console.log('data to update hardwarelist: ', hardwareListData);
    this.httpClient
      .put(this.updateHardwareListUrl, JSON.stringify(hardwareListData), this.httpOptions)
      .pipe(
        //  TODO catcherror snackbar
      )
      .subscribe(
        //  TODO add succss snackbar
      );

  }

  // add new hardware to list in afas
  addHardwareListItem(info: any) {
    const hardwareListData = {
      KnCustomK04: {
        Element: {
          Fields: {
            SqNo: info.seqNum,
            Da: '2021-09-15',
            UC54F5F9B42D4C30072BFD18D73FE8AB8: info.pcName,
            U314C17EE49D3898F82908194F628E5C4: info.pcModel,
            U37649814484AA5AB1371B7B3DDFA7FF1: info.processorType,
            UAE4CFEDD485E1BB9255E39897D5ED209: info.graphicsCard,
            U41641131449E27D81B163289B04FC450: info.serialKey,
            // clean installed ?
            UFED309314F895CF2871CA081D2A11697: true,

            U127432144327AD7E8562A2BF923AD05B: info.lastInstallDate,
            U3088E0C743050BC8FEFEE784BE2DF5DF: info.warrantyExDate,
            U7F55BC694ECEE2C73EA1A19894C4F4D0: info.purchaseDate,
            // excrypted with bitlocker?
            U22430A5645300B1AA800C3948238F64C: true,

            UF2045CFE44A5A521F2A935A1332914A8: info.addInfo,
            U76152D4449509E29E1064EB5C0050447: info.ssd,
            U67DC55804034541DAADE5E871F1141B1: info.ram,
            U2A2C04B54FEB6294715554AC869D89C0: info.location,
            U6CF658494F411DE6886568967A60E20C: info.productKey,
            UC359E6B84BF0FD5B9FDAA49B388BDF7A: info.owner
          }
        }
      }
    };

    console.log('data to add hardwareList item: ', hardwareListData);
    this.httpClient
      .post(this.updateHardwareListUrl, JSON.stringify(hardwareListData), this.httpOptions)
      .pipe(
      //  TODO add catcherror
      )
      .subscribe(
      //  TODO add succss snackbar
      );

  }
}
