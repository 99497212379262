import { Component, OnInit } from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {PhoneList} from '../../../models/phonelist-interface';
import {PhoneListService} from './phone-list.service';
import {MatDialog} from '@angular/material/dialog';
import {PhoneListDialogComponent} from './phone-list-dialog/phone-list-dialog.component';

@Component({
  selector: 'app-phone-list',
  templateUrl: './phone-list.component.html',
  styleUrls: ['./phone-list.component.css']
})
export class PhoneListComponent implements OnInit {

  constructor(
    private getPhoneListItems: PhoneListService,
    public dialog: MatDialog,
    ) { }

  displayedColumns: string[] = ['pcNumber', 'initials', 'phoneNumber', 'edit'];
  dataSource: PhoneList[] = [];
  isLoadingResults = false;
  isRateLimitReached = false;

  tableDataSource: MatTableDataSource<PhoneList>;

  ngOnInit(): void {
    this.getPhoneListItems.getPhoneListItems().subscribe((result) => {
      this.dataSource = result.rows;
      this.tableDataSource = new MatTableDataSource(this.dataSource);
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.tableDataSource.filter = filterValue.trim().toLowerCase();
  }

  openPhoneListDialog(event: any, openedFromAdd: boolean) {
    const dialogRef = this.dialog.open(PhoneListDialogComponent, {
      width: '1000px',
      data: {
        phone: event,
        openedFromAdd
      },
    });
    console.log(event);

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

}
// TODO: create delete button for phone list
