<app-spinner-overlay *ngIf="isLoadingResults">{{ isLoadingResults }}</app-spinner-overlay>

<mat-form-field>
  <mat-label>Search for employee</mat-label>
  <input matInput (keyup)="applyFilter($event)">
</mat-form-field>

<table mat-table [dataSource]="tableDataSource" matSort class="mat-elevation-z8">
 <!-- Product Name Column -->
 <ng-container matColumnDef="Photo">
  <th mat-header-cell *matHeaderCellDef>  </th>
  <td mat-cell *matCellDef="let element"><img class="peopleImage" src="{{element.Photo}}" alt=""></td>
</ng-container>

 <ng-container matColumnDef="firstName">
  <th mat-header-cell *matHeaderCellDef> First Name </th>
  <td mat-cell *matCellDef="let element"> {{element.firstName}} </td>
</ng-container>

<ng-container matColumnDef="lastName">
  <th mat-header-cell *matHeaderCellDef> Last Name </th>
  <td mat-cell *matCellDef="let element"> {{element.lastName}} </td>
</ng-container>

<ng-container matColumnDef="initials">
  <th mat-header-cell *matHeaderCellDef> Initials </th>
  <td mat-cell *matCellDef="let element"> {{element.initials}} </td>
</ng-container>

<ng-container matColumnDef="phoneNumber">
  <th mat-header-cell *matHeaderCellDef> Internal Number </th>
  <td mat-cell *matCellDef="let element"> {{element.phoneNumber}} </td>
</ng-container>

<ng-container matColumnDef="workMobile">
  <th mat-header-cell *matHeaderCellDef> Work Number </th>
  <td mat-cell *matCellDef="let element"> {{element.workMobile}} </td>
</ng-container>

<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<!-- PC_Nummer -->
