<div class="row">
  <div class="col-10">
    <mat-form-field>
      <mat-label>Search...</mat-label>
      <input matInput (keyup)="applyFilter($event)">

    </mat-form-field>
  </div>
  <div class="col-2">
    <button mat-button class="twd_btn" (click)="openHardwareListDialog('', true)">Add</button>
  </div>
</div>




<table mat-table [dataSource]="tableDataSource" matSort class="mat-elevation-z8">
  <!--  Owner colimn-->
  <ng-container matColumnDef="owner">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Owner </th>
    <td mat-cell *matCellDef="let element">{{element.owner}}</td>
  </ng-container>

  <ng-container matColumnDef="pcName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Workstation </th>
    <td mat-cell *matCellDef="let element">{{element.pcName}}</td>
  </ng-container>

  <ng-container matColumnDef="pcModel">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Model </th>
    <td mat-cell *matCellDef="let element">{{element.pcModel}}</td>
  </ng-container>

  <ng-container matColumnDef="serialKey">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Serial Key </th>
    <td mat-cell *matCellDef="let element">{{element.serialKey}}</td>
  </ng-container>

  <ng-container matColumnDef="lastInstallDate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Installed Date </th>
    <td mat-cell *matCellDef="let element">{{element.lastInstallDate | date:"dd/MM/yyyy" }}</td>
  </ng-container>

  <ng-container matColumnDef="encrypted">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Encrypted</th>
    <td mat-cell *matCellDef="let element">{{element.encrypted}}</td>
  </ng-container>

  <ng-container matColumnDef="edit">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <button mat-raised-button class="twd_btn" (click)="openHardwareListDialog(element, false)">Edit</button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
