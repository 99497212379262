import { Component, OnInit } from '@angular/core';
import {GetSearchFilesService} from './get-twdSearchFiles.service';
import { PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { FormControl, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { AuthService } from 'src/app/auth.service';
import { IpService } from 'src/app/ip.service';
import { AppComponent } from 'src/app/app.component';
import {environment} from '../../../environments/environment';


@Component({
  selector: 'app-twd-search',
  templateUrl: './twd-search.component.html',
  styleUrls: ['./twd-search.component.css'],
})
export class TwdSearchComponent implements OnInit {
  constructor(
    private GetSearchFilesService: GetSearchFilesService,
    public dialog: MatDialog,
    public appComponent: AppComponent,
    public authService: AuthService,
    private ip: IpService) { }

  dataSource: any = [];
  // data retrieved from afas call to projects
  projectData: any = [];
  length = 10;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  pageIndex = 0;

  pageEvent: PageEvent;
  searchQuery: string;

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });

  involved = new FormControl();
  involvedList: string[] = [];

  docType = new FormControl();
  documentTypeList: string[] = [];

  docLocation = new FormControl();
  documentLocationList: string[] = ['Projects', 'Library', 'Archive'];

  searchFileContent: string;
  searchContents: string[] = ['Search in title', 'Search in file content'];

  // create empty list for all folders
  projectFoldersList: string[] = [];

  ipAddress = this.appComponent.ipAddress;
  private solrLink: any;



  ngOnInit() {
    // this.getIP();
    this.setSolrLink();
    this.onLoadResults();
  }

  getIP() {
    this.ip.getIPAddress()
    .subscribe(
      (res: any) => {
      this.ipAddress = res.ip;
    });
  }

  setSolrLink() {
    this.solrLink = `${environment.solrLink}/solr/`;
  }

  // get initial search results
  onLoadResults() {
    this.GetSearchFilesService.get_twdsearch_files_onload(this.solrLink).subscribe((result) => {
      console.log(result);
      this.dataSource = result;
      // this.createDocumentLinks(result.response);
    });

    // get list of names involved and push to involved list
    this.GetSearchFilesService.get_twdsearch_names_onload(this.solrLink).subscribe((result) => {
      // console.log(result.facet_counts.facet_fields.doc_involved);
      for (const name of result.facet_counts.facet_fields.doc_involved) {
        if (!isNaN(name)) {
          console.log('');
        } else {
          this.involvedList.push(name);
        }
      }
    });

    // get list of filetypes and push to doc_type list
    this.GetSearchFilesService.get_twdsearch_filetypes_onload(this.solrLink).subscribe((result) => {
      // console.log(result.facet_counts.facet_fields.doc_type);
      for (let doc_type of result.facet_counts.facet_fields.doc_type) {
        if (!isNaN(doc_type)) {
          console.log('');
        } else {
          this.documentTypeList.push(doc_type);
        }
      }
    });

    this.GetSearchFilesService.get_projects_onload(this.solrLink).subscribe((result) => {
      console.log('projects results: ' + result.rows);
      this.projectData = result;
    });
  }

  // get results when changing tab in the search
  onTabLoad(event) {
    console.log(event);
    this.getIP();
    this.setSolrLink();
    this.GetSearchFilesService.get_twdsearch_files_onTab(
      this.solrLink,
      this.getValueForpageSize(event),
      this.getValueForpageIndex(event),
      this.searchFileContent ? this.getValueForSearchFileContent() : this.getValueForSearchBar(),
      this.getValueForFileTypeOnTab(event.tab.textLabel),
      event.tab.textLabel === 'advanced search' ? this.getValueForFileLocation(event) : '',
      ).subscribe((result) => {
      console.log(result);
      this.dataSource = result;
      // this.createDocumentLinks(result.response);
    });
  }

  // results when choosing doc_types on Tabs (Project Folder)
  onSearchLocationChange(event, tab){
    console.log(event);
    this.getIP();
    this.setSolrLink();
    this.GetSearchFilesService.get_twdsearch_files_onTab(
      this.solrLink,
      this.getValueForpageSize(event),
      this.getValueForpageIndex(event),
      this.searchFileContent ? this.getValueForSearchFileContent() : this.getValueForSearchBar(),
      this.getValueForFileTypeOnTab(tab),
      tab === 'advanced search' ? this.getValueForFileLocation(event) : '',
      ).subscribe((result) => {
      console.log(result);
      this.dataSource = result;
      // this.createDocumentLinks(result.response);
    });
  }

  onPageChange(event, selectedPage: string = '') {
    console.log(event);
    console.log(selectedPage);
    this.getIP();
    this.setSolrLink();
    this.GetSearchFilesService.get_twdsearch_files(
      this.solrLink,
      this.getValueForpageSize(event),
      this.getValueForpageIndex(event),
      this.searchFileContent === 'Search in file content' ? this.getValueForSearchFileContent() : this.getValueForSearchBar(),
      this.getValueForDateRange(this.range),
      '', // this.getValueForInvolvedList(this.involved),
      this.getValueForFileType(this.docType),
      '', // this.getValueForFileLocationOnFilter(event)
    ).subscribe((result) => {
      console.log(result.response);
      this.dataSource = result;
      // this.createDocumentLinks(result.response);
      console.log('retrieved on change data');
    });

    this.GetSearchFilesService.get_projects(
      this.solrLink,
      this.getValueForpageSize(event),
      this.getValueForpageIndex(event),
      this.getValueForProjectSearchBar(),
    ).subscribe((result) => {
      console.log('project results: ' + result.response);
      this.projectData = result;
      console.log('retrieved on change project data');
    });
  }

  onPaginationChange(event, tab) {
    console.log(event);
    console.log(tab);
    this.getIP();
    this.setSolrLink();
    this.GetSearchFilesService.get_twdsearch_files_onTab(
      this.solrLink,
      this.getValueForpageSize(event),
      this.getValueForpageIndex(event),
      this.searchFileContent ? this.getValueForSearchFileContent() : this.getValueForSearchBar(),
      this.getValueForFileTypeOnTab(tab),
      '' // this.getValueForFileLocationOnFilter(tab)
      ).subscribe((result) => {
      console.log(result);
      this.dataSource = result;
      // this.createDocumentLinks(result.response);
    });

  }

  onProjectPaginationChange(event) {
    console.log(event);
    this.GetSearchFilesService.get_projects_onTab(
      this.solrLink,
      this.getValueForpageSize(event),
      this.getValueForpageIndex(event),
      this.getValueForProjectSearchBar()
    ).subscribe((result) => {
      console.log(result);
      this.projectData = result;
    });
  }

  // get search field query
  getValueForSearchBar() {
    let returnvalue = this.searchQuery;

    if (returnvalue === undefined || returnvalue === '') {
      returnvalue = '';
    } else {
      returnvalue = '&fq=doc_name:' + this.searchQuery;
    }
    console.log('value for searchbar: ', returnvalue);
    return returnvalue;
  }

  getValueForProjectSearchBar() {
    let returnvalue = this.searchQuery;

    if (returnvalue === undefined || returnvalue === '') {
      returnvalue = '';
    } else {
      returnvalue = '&fq=project_name:(' + this.searchQuery + ')';
    }
    console.log('value for project searchbar: ', returnvalue);
    return returnvalue;
  }

  // get afas search field query
  getValueForAfasSearchBar() {
    let returnvalue = this.searchQuery;

    if (returnvalue === undefined || returnvalue === '') {
      returnvalue = '';
    } else {
      returnvalue = this.searchQuery;
    }
    console.log('value for searchbar: ', returnvalue);
    return returnvalue;
  }

  // get value if search file content is checked
  getValueForSearchFileContent() {
    let returnValue = this.searchFileContent;
    // if field not checked and no search query filled in do nothing
    returnValue === 'Search in title' || this.searchQuery === undefined ? returnValue = '' : returnValue = '&fq=__text__:' + this.searchQuery;

    console.log('search in folder content: ', returnValue);
    return returnValue;

  }

  // get pagesize value [10 - 50 - 100]
  getValueForpageSize(event) {
    let returnvalue = event.pageSize;

    if (returnvalue === undefined) {
      returnvalue = 100;
    } else {
      returnvalue = event.pageSize;
    }
    console.log('selected items per page: ', returnvalue);
    return returnvalue;
  }

  // get index of page [items per page + 1 = start parameter]
  getValueForpageIndex(event) {
    let returnvalue = event.pageIndex;

    if (returnvalue === undefined) {
      returnvalue = 0;
    } else {
      returnvalue = event.pageIndex;
    }
    console.log('pagination number: ', returnvalue);
    return returnvalue;
  }


  getValueForDateRange(range) {
    let returnvalue = range.value;
    const datePipe: DatePipe = new DatePipe('en-US');

    if (returnvalue.start === null && returnvalue.end === null) {
      returnvalue = '';
    } else {
      returnvalue = '&fq=doc_last_mod_date:[' + datePipe.transform(range.value.start, 'yyyy-MM-ddTHH:mm:ss')
        + 'Z%20TO%20'
        + datePipe.transform(range.value.end, 'yyyy-MM-ddTHH:mm:ss')
        + 'Z]';
    }
    console.log(returnvalue);
    return returnvalue;
  }

  // TODO
  getValueForInvolvedList(selection) {
    let returnvalue = selection.value;
    const returnvalueList = [];
    if (returnvalue === null || returnvalue === undefined) {
      returnvalue = '&fq=doc_involved:*';
    } else {
      for (const selected of selection.value) {
        returnvalueList.push(selected);
      }
    }
    console.log('value for involved filter: ', returnvalue);
    return '&fq=doc_involved:' + returnvalueList;
  }

  getValueForFileType(selection) {
    let returnvalue = selection.value;
    if (returnvalue === null || returnvalue === undefined || returnvalue.length === 0) {
      returnvalue = '*';
    } else if (returnvalue === 'Standard & codes'){
      returnvalue =  '\"Standard and codes\"';
    } else {
      returnvalue = selection.value;
    }
    console.log('value for filetype filter: ', returnvalue);
    return '&fq=doc_type:' + returnvalue;
  }

  getValueForFileTypeOnTab(event) {
    let returnvalue = event;
    console.log(returnvalue);
    if (returnvalue === null || returnvalue === undefined || returnvalue === 'Advanced Search') {
      returnvalue = '&fq=doc_type:*';
    } else if (returnvalue === 'Standard & codes'){
      returnvalue =  '\"Standard and codes\"';
    } else {
      returnvalue = '&fq=doc_type:' + returnvalue;
      }
    console.log(returnvalue);
    return returnvalue;
  }

  // parameters for solr when Tab is switched in search tool
  getValueForFileLocation(event) {
    let returnvalue = event.tab.textLabel;
    console.log(returnvalue);
    if (returnvalue === null || returnvalue === undefined || returnvalue === 'Advanced Search') {
      returnvalue = '';
    } else if (returnvalue === 'Procedures' || returnvalue === 'Templates' || returnvalue === 'Workshops' || returnvalue.value === 'Library') {
      returnvalue = '&fq=file_loc:"N:/Library"';
    }  else {
      returnvalue = '&fq=file_loc:"N:/Projects"';
    }
    console.log('value for file location search: ', returnvalue);
    return returnvalue;
  }

  // // parameters for solr when search location is filtered
  // getValueForFileLocationOnFilter(event) {
  //   console.log(this.doc_location);
  //   let returnvalue = this.doc_location.value;
  //   console.log(returnvalue);
  //   if (returnvalue === null || returnvalue === undefined) {
  //     returnvalue = '';
  //   } else if (returnvalue === 'Procedures' || returnvalue === 'Templates' || returnvalue === 'Workshops' || returnvalue.value === 'Library') {
  //     returnvalue = '&fq=file_loc:"N:\\\\Library"';
  //   } else if (returnvalue === 'Archive'){
  //     returnvalue = '&fq=file_loc:*twdarchivestore*';
  //   } else {
  //     returnvalue = '&fq=file_loc:"N:\\\\Projects"';
  //   }
  //   console.log(returnvalue);
  //   return returnvalue;
  // }

  openDocument(selection) {
    this.GetSearchFilesService.update_twaviews(this.solrLink, selection);
  }



}
