import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {UsersDialogComponent} from '../users-dialog/users-dialog.component';

@Component({
  selector: 'app-experthub-dialog',
  templateUrl: './experthub-dialog.component.html',
  styleUrls: ['./experthub-dialog.component.scss']
})
export class ExperthubDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {

    console.log('this is from dialog: ', this.data.skills);

  }

  openUsersDialog(userData: any) {
    this.dialog.open(UsersDialogComponent, {
      width: '1000px',
      data: {
        users: userData,
      },
    });

    console.log(`user_data: ${userData}`);
  }

}
