<!--<table mat-table [dataSource]="tableDataSource" matSort class="mat-elevation-z8">-->
<!--  &lt;!&ndash; Product Name Column &ndash;&gt;-->

<!--  <ng-container matColumnDef="empMail">-->
<!--    <th mat-header-cell *matHeaderCellDef> Name </th>-->
<!--    <td mat-cell *matCellDef="let element"> {{element.empMail}} </td>-->
<!--  </ng-container>-->

<!--  <ng-container matColumnDef="sessionDuration">-->
<!--    <th mat-header-cell *matHeaderCellDef> Session Duration </th>-->
<!--    <td mat-cell *matCellDef="let element"> {{element.sessionStart}} </td>-->
<!--  </ng-container>-->

<!--  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>-->
<!--  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>-->
<!--</table>-->

<div>
  <div class="cards-container">
    <div fxLayout="row wrap" fxLayoutGap="16px grid">
      <div fxFlex="16%" fxFlex.xs="80%" fxFlex.sm="33.3%" *ngFor="let user of autodeskLicenseData">
        <mat-card class="example-card">
          <mat-card-header>
            <div mat-card-avatar class="example-header-image"></div>
            <mat-card-title>{{user.empMail}}</mat-card-title>
            <mat-card-subtitle>{{user.sessionStart}}</mat-card-subtitle>
          </mat-card-header>
        </mat-card>
      </div>
    </div>
  </div>
</div>

<!-- PC_Nummer -->
