// tslint:disable: max-line-length
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { Observable, of } from 'rxjs';
import { NotificationService } from '../../../notification-service/notification.service';
import { catchError } from 'rxjs/operators';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GenExportService {

constructor(
  private httpClient: HttpClient,
  private datePipe: DatePipe,
  private Notification_Service: NotificationService,
) { }


// TEST TOKEN
private token = environment.token;
private href = environment.gen_Export_url;

// // LIVE TOKEN
// private token = 'AfasToken PHRva2VuPjx2ZXJzaW9uPjE8L3ZlcnNpb24+PGRhdGE+NkRFQUNCOUVFNTBFNDlGRjk2MzNFRTlDRjREQzA4MTA1OUNGRjJERjQ0NUQ3REU5NkYzQTRBOTIzMkM4MjFENjwvZGF0YT48L3Rva2VuPg==';
// private href = 'https://87674.rest.afas.online/ProfitRestServices/connectors/FiInvoice';

UpdateExport(row: any) {

  const httpOptions = {
    headers: new HttpHeaders({ 'content-type': 'application/json', Authorization: this.token })
  };

  // CHECK SELECTION OM TE ZIEN WELKE RIJ IS GESELECTEERD

  // GET DATE IN AFAS FORMAT
  const currentDate = new Date();
  this.datePipe.transform(currentDate, 'yyyy-MM-dd');

  // FOR LOOP OM DE STATUS VAN DE PROJECTFACTUUR OP EXPORTED TE ZETTEN
    const exportdata = {
      FiInvoice: {
        Element: {
          Fields: {
            UnId: row.Administratie,
            InId: row.Factuurnummer,
            U1504586C48EDCB5DD9B31781734D0D5F: true,
            UCA040EF24F700697C5EEC0B4D00D0C7F: currentDate,
          }
        }
      }
    };

  // CHECK VOOR GEGENEREERDE JSON PAYLOAD

  // UPDATE CONNECTOR VOOR AANPASSEN VAN EXPORT STATUS
  this.httpClient.put(this.href, JSON.stringify(exportdata), httpOptions)
      .pipe( // HERE COMES THE ERROR MESSAGES
        catchError(this.handleError<any>('getheroes', row.Factuurnummer))
        )
      .subscribe();
    }

  private handleError<T>(operation = 'operation', FactNr: string, result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      // console.error(error);

      // TODO: better job of transforming error for user consumption
      this.Notification_Service.sendSnack(FactNr + ' mislukt: ' + error.error.externalMessage, 5000);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  }
