// tslint:disable: max-line-length
import { Injectable, ErrorHandler, OnInit } from '@angular/core';
import { FactuurModel, BalieIssue, HttpResponseBalie, VerdichteRegel, VerdichteRegelsCollection, InvoicesWithVerdichteRegelsCollections } from './main_models';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { of, Observable, Subscription, Subject } from 'rxjs';
import { NotificationService } from '../../../notification-service/notification.service';
import { map, retry, catchError,  } from 'rxjs/operators';
import {environment} from '../../../../environments/environment';




@Injectable({
  providedIn: 'root'
})
export class GenHTMLRegelsService implements OnInit {

  constructor(
    private httpClient: HttpClient,
    private datePipe: DatePipe,
    private Notification_Service: NotificationService,
    private _httpRegels: HttpClient,
  ) { }

  // Make a dict with invoices that have verdichte regels inside them
  InvoicesWithVerdichteRegels_dict = {} as InvoicesWithVerdichteRegelsCollections;

  // Make a subject that will be used for updating the local data in the table-httml-example.ts
  InvoicesWithVerdichteRegelsSubject = new Subject<{}>();

  ngOnInit() {
  }

  Generate_Invoicedata_for_table(InvoiceArray: FactuurModel[]) {

    // Make the verdichte regels. This is used for the HTML overview and for send the data back to AFAS
    // Keep in mind that this function can be called multiple times in current setup
    const factuur_dict: any = {};

    // var facturregels_dict: {itemcode: {aantal: 0, prijs_per_e: '', bedrag: '', administratie: ''}}
    let gen_factuurregels: BalieIssue[] = [];


    // GENEREER Invoice DICTIONARY
    for ( const dict_number of InvoiceArray) {
      factuur_dict[dict_number.Factuurnummer] = dict_number;
    }


    // Make a new dict for the invoices, first delete any possible old ones.
    delete(this.InvoicesWithVerdichteRegels_dict);
    this.InvoicesWithVerdichteRegels_dict = {} as InvoicesWithVerdichteRegelsCollections;

    // For each invoice
    for (const Invoice of InvoiceArray) {
      // Get all regels of this period, filtering is done in AFAS
      this.getFactRegels(Invoice.Factuurnummer).subscribe((data: HttpResponseBalie) => {
        // Store all regels in local var
        gen_factuurregels = data.rows;

        // Make verdichte regels for this period, first an empty dict
        const facturregels_dict = {} as VerdichteRegelsCollection;

        // For all the regels in this invoice
        for (let i = 0; i < data.rows.length; i++) {
          // If the itemcode is not in the verdichte regels overview, add it to the overview
          if (!(data.rows[i].Itemcode in facturregels_dict)) {
            facturregels_dict[data.rows[i].Itemcode] = {
              hours: 0, euros: 0, Prijs_per_eenheid: data.rows[i].Prijs_per_eenheid, regelnummers: []
              };
          }

          // Fill data of the current regel into the right verdichte regel
          facturregels_dict[data.rows[i].Itemcode].euros += +data.rows[i].Aantal_eenheden * +data.rows[i].Prijs_per_eenheid;
          facturregels_dict[data.rows[i].Itemcode].hours += +data.rows[i].Aantal_eenheden;
          facturregels_dict[data.rows[i].Itemcode].regelnummers.push(data.rows[i]);
        }

        // All the regels of the invoice were scanned, store the verdichte regels in the factuur dict
        this.InvoicesWithVerdichteRegels_dict[Invoice.Factuurnummer] = facturregels_dict;

        // Trigger the subject, this will cause the subscription in table-http-example.ts to trigger an update of the local copy of the invoices with verdichte regels
        this.InvoicesWithVerdichteRegelsSubject.next('');

      });

    }
  }

  // FUNCTIE GET FACTUURREGELS MET FILTER
  getFactRegels(factuurnummer: string): Observable<HttpResponseBalie> {

    // TOKEN
    const token = environment.token;
    const href = environment.gen_HTML_regels_url + factuurnummer + '&operatortypes=1&skip=0&take=5000';
    const httpOptions = {
      headers: new HttpHeaders({ 'content-type': 'application/json', 'Authorization': token })
    };

    return this._httpRegels.get<HttpResponseBalie>(href, httpOptions)
    .pipe(
      retry(1)
    );
  }
}

