import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpResponsePhonelist, HttpResponseUsers } from '../../../../models/phonelist-interface';
import {environment} from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GetPhonelistService {

  private href = environment.get_PhoneList;
  private employeesLink = environment.get_EmployeesForPhoneList;
  private token = environment.token;
  private httpOptions = {
    headers: new HttpHeaders({
      'content-type': 'application/json',
      Authorization: this.token
    }),
  };

  constructor(private http: HttpClient) { }

  // get phone list data from afas
  get_PhoneList_AFAS(): Observable<HttpResponsePhonelist> {
    return this.http.get<HttpResponsePhonelist>(this.href, this.httpOptions);
  }

  // get employee photo from afas
  get_Photo_AFAS(): Observable<HttpResponseUsers> {
    return this.http.get<HttpResponseUsers>(this.employeesLink, this.httpOptions);
  }

}
