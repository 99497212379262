import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-rfem',
  templateUrl: './rfem.component.html',
  styleUrls: ['./rfem.component.scss']
})
export class RfemComponent implements OnInit {

  rfemLicenseData: any = [];

  constructor(
    private http: HttpClient
  ) { }

  ngOnInit() {
    this.http.get('https://twdarchivestore.file.core.windows.net/archive/Hosted_Files/rfemlicense/rfem_output_users.json?sv=2021-06-08&ss=bfqt&srt=sco&sp=r&se=2024-12-21T22:21:47Z&st=2022-12-21T14:21:47Z&spr=https&sig=f13x6nxMBBtVbwZQSyowYzbcdHNNAsyQV4r7%2FWZX94s%3D').subscribe((res: any) => {
      this.rfemLicenseData = res.rfemUserList;
    });

  }
}
