import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth.service';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-licenses',
  templateUrl: './licenses.component.html',
  styleUrls: ['./licenses.component.scss']
})
export class LicensesComponent implements OnInit {

  public rfemLicenseData: any = [];
  public autodeskLicenseData: any = [];
  public ansysLicenseData: any = [];

  constructor(public authService: AuthService, private http: HttpClient) { }



  ngOnInit() {
    this.http.get('https://twdarchivestore.file.core.windows.net/archive/Hosted_Files/rfemlicense/rfem_output_users.json?sv=2021-06-08&ss=bfqt&srt=sco&sp=r&se=2024-12-21T22:21:47Z&st=2022-12-21T14:21:47Z&spr=https&sig=f13x6nxMBBtVbwZQSyowYzbcdHNNAsyQV4r7%2FWZX94s%3D').subscribe((res) => {
      this.rfemLicenseData = res;
      console.log(`rfem license data: ${this.rfemLicenseData.rfemUserList}`);
    });


    this.http.get('assets/license_files/ansys_output_users.json').subscribe((res) => {
      this.ansysLicenseData = res;
      console.log(`ansys license data: ${this.ansysLicenseData[0]}`);
    });



  }

}
