<!-- Display spinner when something is loading-->
<app-spinner-overlay *ngIf="isLoading">{{ isLoading }}</app-spinner-overlay>

<div style="justify-content: center;">
  <!-- <div *ngIf="authService.authenticated"></div> -->


  <mat-tab-group class="workshop_overview">
    <mat-tab label="Workshops">
      <div class="cards-container">
        <div *ngFor="let event of workshopData">
          <div *ngIf="event.attendeesCount < event.attendeesMax; then show">
          </div>
          <ng-template #none></ng-template>
          <ng-template #show>
            <mat-card class="example-card">
              <mat-card-header>
                <div mat-card-avatar matBadge="{{event.attendeesCount}}" class="example-header-image"></div>
                <mat-card-title>{{event.workshopTitle}}</mat-card-title>
                <mat-card-subtitle>{{event.workshopGroup}}</mat-card-subtitle>
                <mat-card-subtitle>By: {{event.presenter}}</mat-card-subtitle>
                <mat-card-subtitle>Max: {{event.attendeesMax}}</mat-card-subtitle>
              </mat-card-header>
              <mat-card-content style="max-width: 380px;">
                <mat-expansion-panel class="mat-elevation-z0" style="width: 100%;">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <b>Description</b>
                    </mat-panel-title>
                    <mat-panel-description></mat-panel-description>
                  </mat-expansion-panel-header>
                  <p>{{event.workshopDescription}}</p>
                  <p><b>Goal:</b></p>
                  <p>{{event.workshopGoal}}</p>
                  <p><b>Target:</b></p>
                  <p>{{event.workshopTarget}}</p>
                </mat-expansion-panel>
              </mat-card-content>
              <div class="time_and_date">
                <button mat-button style="padding-left: 6%;">
                  {{event.workshopDate | date:'dd-MM-yyyy' }}
                </button>

                <button mat-button>
                  {{event.workshopStarttime}} - {{event.workshopEndtime}}
                </button>
              </div>
              <mat-card-actions id="bottom" style="padding-left: 6%; padding-right: 6%; display: flex; place-content: space-between;">
                <button mat-raised-button class="twd_btn"
                        (click)=joinWorkShop(event)> Join Workshop </button>
                <button mat-raised-button class="twd_btn_warning"
                (click)=signOutOfWorkshop(event)>Sign Out Of Workshop</button>
              </mat-card-actions>
              <mat-card-footer></mat-card-footer>
            </mat-card>
          </ng-template>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="My Workshops">
      <div class="cards-container">
        <div *ngFor="let event of workshopAttendeesData">
          <div *ngIf="event.attendeeABB == loggedInUserAbb; then show">
          </div>
          <ng-template #none></ng-template>
          <ng-template #show>
            <mat-card class="example-card">
              <mat-card-header>
                <div mat-card-avatar matBadge="{{event.attendeesCount}}" class="example-header-image"></div>
                <mat-card-title>{{event.workshopTitle}}</mat-card-title>
                <mat-card-subtitle>{{event.workshopGroup}}</mat-card-subtitle>
                <mat-card-subtitle>By: {{event.presenter}}</mat-card-subtitle>
                <mat-card-subtitle>Max: {{event.attendeesMax}}</mat-card-subtitle>
              </mat-card-header>
              <mat-card-content style="max-width: 380px;">
                <mat-expansion-panel class="mat-elevation-z0" style="width: 100%;">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <b>Description</b>
                    </mat-panel-title>
                    <mat-panel-description></mat-panel-description>
                  </mat-expansion-panel-header>
                  <p>{{event.workshopDescription}}</p>
                  <p><b>Goal:</b></p>
                  <p>{{event.workshopGoal}}</p>
                  <p><b>Target:</b></p>
                  <p>{{event.workshopTarget}}</p>
                </mat-expansion-panel>
              </mat-card-content>
              <button mat-button style="padding-left: 6%;">
                {{event.workshopDate | date:'dd-MM-yyyy' }}
              </button>

              <button mat-button>
                {{event.workshopStarttime}} -
                {{event.workshopEndtime}}
              </button>
              <mat-card-actions id="bottom" style="padding-left: 6%; padding-right: 6%; display: flex; place-content: space-between;">
                <button mat-raised-button class="twd_btn_warning"
                (click)=signOutOfWorkshop(event)>Sign Out Of Workshop</button>
              </mat-card-actions>
              <mat-card-footer></mat-card-footer>
            </mat-card>
          </ng-template>

        </div>
      </div>
    </mat-tab>
    <mat-tab label="Workshop Manager">
      <table mat-table [dataSource]="workshopData" class="admin-table" matSort
             matSortActive="created" matSortDisableClear matSortDirection="desc">

        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef>Start Date</th>
          <ng-container *matCellDef="let row">

              <td mat-cell> {{row.workshopDate | date:'dd-MM-yyyy' }}</td>

          </ng-container>
        </ng-container>

        <ng-container matColumnDef="subject">
          <th mat-header-cell *matHeaderCellDef>Subject</th>
          <ng-container *matCellDef="let row" >

              <td mat-cell>{{row.workshopTitle}}</td>

          </ng-container>
        </ng-container>

        <ng-container matColumnDef="attendees">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row">
            <button mat-raised-button class="twd_btn" (click)="viewAttendees(row)">Attendees</button>
          </td>

        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsManager"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsManager;"></tr>
      </table>
    </mat-tab>
    <mat-tab label="Admin" *ngIf="authenticatedForAdmin.includes(loggedInUser)">

      <table mat-table [dataSource]="workshopData" class="admin-table" matSort
           matSortActive="created" matSortDisableClear matSortDirection="desc">

        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef>Start Date</th>
          <td mat-cell *matCellDef="let row">{{row.workshopDate | date:'dd-MM-yyyy' }}</td>
        </ng-container>


      <ng-container matColumnDef="subject">
        <th mat-header-cell *matHeaderCellDef>Subject</th>
        <td mat-cell *matCellDef="let row">{{row.workshopTitle}}</td>
      </ng-container>

      <ng-container matColumnDef="recap">
        <th mat-header-cell *matHeaderCellDef>Location</th>
        <td mat-cell *matCellDef="let row">{{row.workshopLocation}}</td>
      </ng-container>

      <ng-container matColumnDef="practical">
        <th mat-header-cell *matHeaderCellDef>Attendees</th>
        <td mat-cell *matCellDef="let row">{{row.attendeesCount}}</td>
      </ng-container>


      <ng-container matColumnDef="presenter">
        <th mat-header-cell *matHeaderCellDef>Presenter</th>
        <td mat-cell *matCellDef="let row">{{row.presenter}}</td>
      </ng-container>

      <ng-container matColumnDef="attendees">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          <button mat-raised-button class="twd_btn" (click)="viewAttendees(row)">Attendees</button>
        </td>

      </ng-container>

<!--      <ng-container matColumnDef="edit">-->
<!--        <th mat-header-cell *matHeaderCellDef></th>-->
<!--        <td mat-cell *matCellDef="let row">-->
<!--          <button mat-raised-button class="twd_btn" (click)="editWorkshop(row)">Edit Workshop</button>-->
<!--        </td>-->

<!--      </ng-container>-->

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </mat-tab>
  </mat-tab-group>

</div>



<!--  && formatDateTimeTimeZone(currentDate) < formatDateTimeTimeZone(event.start) -->
