<app-nav-bar></app-nav-bar>
<main role="main" class="container-fluid">

  <div class="app_main_outer">
    <div class="app_main_inner">

      <!-- <div *ngIf="authService.authenticated; then welcomeUser else signInPrompt" ></div> -->
      <!-- <ng-template #signInPrompt >
        <h2 style="text-align: center;">Welcome to the TWD Dashboard</h2> <br>


        <p style="text-align: center;">If you are working from home you have to take some aditional steps.</p> <br>
        <div style="margin-left: 40px; text-align: center;">
          1. Make sure your VPN is connected <br>
          2.  <a href="https://192.168.1.240:8983" target="_blank" class="mat-raised-button">Click Here</a> and accept the security risks to access the website and then close the page<br>
          3. <a href="https://www.data.twd.nl" target="_blank" class="mat-raised-button">Click Here</a> and accept the security risks to access the website and then close the page <br><br>
        </div>
        <p style="text-align: center;"> Now you can </p>
        <button mat-raised-button (click)="signIn()" class="nav-button" >Sign In</button>
        <p style="text-align: center;">to use the dashboard</p>

      </ng-template> -->
      <!-- <ng-template #welcomeUser>
      </ng-template> -->

      <div joyrideStep="one" stepPosition="center" [stepContent]="externalNotification"></div>
      <div joyrideStep="two" stepPosition="center" [stepContent]="link1"></div>

      <!-- joyride notifications -->
      <ng-template #externalNotification>
        <h5 style="color: red; text-align: center;">! IMPORTANT</h5>
        <p>It appears you are working from outside of the office</p>
        <h6 style="font-weight: 600; text-align: center">{{ipAddress}}</h6>
      </ng-template>
      <ng-template #link1>
        Make sure your VPN is connected
      </ng-template>

      <app-alerts></app-alerts>
     <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
     <router-outlet *ngIf="!authService.isIframe"></router-outlet>
    </div>
  </div>

</main>
