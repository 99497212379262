<mat-dialog-content class="mat-typography">

  <form [formGroup]="mobileDetailForm" (ngSubmit)="onSubmit()">

    <div class="form-group row">
<!--      header group -->
      <div class="col-12">
        <h6>Employee</h6>
      </div>
<!--      owner-->
      <div class="col-1 form-label">
        <label for="owner">Owner</label>
      </div>
      <div class="col-2">
        <input
          type="text"
          class="form-control"
          formControlName="owner"
          [ngClass]="{ 'is-invalid': submitted && f.owner.errors }"
        >
        <div *ngIf="submitted && f.owner.errors" class="invalid-feedback">
          <div *ngIf="f.owner.errors.required"> Owner is required</div>
          <div *ngIf="f.owner.errors.maxlength"> Owner is too long</div>
        </div>
      </div>
<!--      location -->
      <div class="col-1 form-label">
        <label for="twdLocation">Location </label>
      </div>
      <div class="col-3">
        <select
          class="form-control"
          formControlName="twdLocation"
          [ngClass]="{ 'is-invalid': submitted && f.twdLocation.errors }"
        >
          <option value="" disabled>Choose the location</option>
          <option *ngFor="let location of location" [ngValue]="location">{{location}}</option>
        </select>
        <div *ngIf="submitted && f.twdLocation.errors" class="invalid-feedback">
          <div *ngIf="f.owner.errors.required"> Location is required</div>
        </div>
      </div>
    </div>
<!--    location + comp-->
    <div class="form-group row">
      <div class="col-12">
        <h6>Compensation</h6>
      </div>
<!--      compensation-->
      <div class="col-2 form-label">
        <label for="compensation">Received </label>
      </div>
      <div class="col-1">
        <input
          type="checkbox"
          class="form-control"
          formControlName="compensation"
          [ngClass]="{ 'is-invalid': submitted && f.compensation.errors }"
        >
        <div *ngIf="submitted && f.compensation.errors" class="invalid-feedback">
          <div *ngIf="f.compensation.errors.required"> Received is required</div>
        </div>
      </div>
<!--      compensation ammount-->
      <div class="col-1 form-label">
        <label for="compensationAmount">Amount </label>
      </div>
      <div class="col-3">
        <input type="text" class="form-control" formControlName="compensationAmount">
      </div>
<!--      compensation date-->
      <div class="col-1 form-label">
        <label for="compensationDate">Date </label>
      </div>
      <div fxLayout="row" class="col3">
        <input
          type="text"
          class="form-control"
          formControlName="compensationDate"
          [matDatepicker]="compensationPicker"
          disabled
          [ngClass]="{ 'is-invalid': submitted && f.compensationDate.errors }"
        >
        <mat-datepicker touchUi #compensationPicker disabled="false"></mat-datepicker>
        <button class="twd_btn" type="button" mat-button (click)="compensationPicker.open()">
          <i class="fas fa-calendar-alt"></i>
        </button>
        <div *ngIf="submitted && f.compensationDate.errors" class="invalid-feedback">
          <div *ngIf="f.compensationDate.errors.required"> Compensation date is required</div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-12">
        <h6>SIM</h6>
      </div>

<!--      Phone Number-->
      <div class="col-2 form-label">
        <label for="phoneNum">Phone Number</label>
      </div>
      <div class="col-3">
        <input
          type="text"
          class="form-control"
          formControlName="phoneNum"
          [ngClass]="{ 'is-invalid': submitted && f.phoneNum.errors }"
        >
        <div *ngIf="submitted && f.phoneNum.errors" class="invalid-feedback">
          <div *ngIf="f.phoneNum.errors.required"> Phone Number is required</div>
          <div *ngIf="f.phoneNum.errors.maxlength"> Phone Number is too long</div>
        </div>
      </div>

<!--      expiration date plan-->
      <div class="col-2 form-label">
        <label for="expDateAbb">Expiration Date Plan </label>
      </div>
      <div fxLayout="row" class="col-3">
        <input
          type="text"
          class="form-control"
          formControlName="expDateAbb"
          [matDatepicker]="expirationPicker"
          disabled
          [ngClass]="{ 'is-invalid': submitted && f.expDateAbb.errors }"
        >
        <mat-datepicker touchUi #expirationPicker disabled="false"></mat-datepicker>
        <button class="twd_btn" type="button" mat-button (click)="expirationPicker.open()">
          <i class="fas fa-calendar-alt"></i>
        </button>
        <div *ngIf="submitted && f.expDateAbb.errors" class="invalid-feedback">
          <div *ngIf="f.expDateAbb.errors.required"> Expiration date is required</div>
        </div>
      </div>

<!--      MBs -->
      <div class="col-1 form-label">
        <label for="mb">MB's </label>
      </div>
      <div class="col-1">
        <input type="text" class="form-control" formControlName="mb">
      </div>

<!--      SIM number-->
      <div class="col-2 form-label">
        <label for="simNum">SIM Number</label>
      </div>
      <div class="col-4">
        <input
          type="text"
          class="form-control"
          formControlName="simNum"
          [ngClass]="{ 'is-invalid': submitted && f.simNum.errors }"
        >
        <div *ngIf="submitted && f.simNum.errors" class="invalid-feedback">
          <div *ngIf="f.simNum.errors.required"> SIM Number is required</div>
        </div>
      </div>

<!--      PUK Code-->
      <div class="col-2 form-label">
        <label for="pukCode">Puk Code</label>
      </div>
      <div class="col-4">
        <input
          type="text"
          class="form-control"
          formControlName="pukCode"
          [ngClass]="{ 'is-invalid': submitted && f.pukCode.errors }"
        >
        <div *ngIf="submitted && f.pukCode.errors" class="invalid-feedback">
          <div *ngIf="f.pukCode.errors.required"> PUK Code is required</div>
        </div>
      </div>

<!--        E-SIM -->
      <div class="col-2 form-label">
        <label for="eSim">E Sim </label>
      </div>
      <div class="col-4">
        <input type="checkbox" class="form-control" formControlName="eSim">
      </div>

<!--      E-PIN-->
      <div class="col-2 form-label">
        <label for="ePin">E Pin </label>
      </div>
      <div class="col-4">
        <input type="text" class="form-control" formControlName="ePin">
      </div>

    </div>


    <div class="form-group row">
      <div class="col-12">
        <h6>Phone</h6>
      </div>
<!--      phone Type-->
      <div class="col-2 form-label">
        <label for="phoneType">Phone Type</label>
      </div>
      <div class="col-10">
        <input type="text" class="form-control" formControlName="phoneType">
      </div>

<!--      IMEI number-->
      <div class="col-2 form-label">
        <label for="imeiNum">Imei Number </label>
      </div>
      <div class="col-2">
        <input
          type="text"
          class="form-control"
          formControlName="imeiNum"
          [ngClass]="{ 'is-invalid': submitted && f.imeiNum.errors }"
        >
        <div *ngIf="submitted && f.imeiNum.errors" class="invalid-feedback">
          <div *ngIf="f.imeiNum.errors.maxlength"> Number is too long</div>
        </div>
      </div>

<!--      IMEI umber 2-->
      <div class="col-2 form-label">
        <label for="imeiNumberTwo">Imei Number 2 </label>
      </div>
      <div class="col-2">
        <input
          type="text"
          class="form-control"
          formControlName="imeiNumberTwo"
          [ngClass]="{ 'is-invalid': submitted && f.imeiNumberTwo.errors }"
        >
        <div *ngIf="submitted && f.imeiNumberTwo.errors" class="invalid-feedback">
          <div *ngIf="f.imeiNumberTwo.errors.maxlength"> Number is too long</div>
        </div>
      </div>

<!--      Serial number-->
      <div class="col-2 form-label">
        <label for="serialNum">Serial Number </label>
      </div>
      <div class="col-2">
        <input type="text" class="form-control" formControlName="serialNum">
      </div>

    </div>

    <!--    submit button-->
    <mat-dialog-actions>
      <button mat-button mat-dialog-close="true" class="twd_btn_warning">Cancel</button>
      <button mat-button type="submit" class="twd_btn">Save</button>
    </mat-dialog-actions>
  </form>

<!--  <pre>{{ mobileDetailForm.value | json }}</pre>-->
</mat-dialog-content>
