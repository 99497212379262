<div>
  <div class="cards-container">
    <div fxLayout="row wrap" fxLayoutGap="16px grid">
      <div fxFlex="16%" fxFlex.xs="80%" fxFlex.sm="33.3%" *ngFor="let user of rfemLicenseData">
        <mat-card class="example-card">
          <mat-card-header>
            <div mat-card-avatar class="example-header-image"></div>
            <mat-card-title>{{user.usr}}</mat-card-title>
            <mat-card-subtitle>opened for: {{user.time_opened}}</mat-card-subtitle>
          </mat-card-header>
        </mat-card>
      </div>
    </div>
  </div>
</div>
