import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-ansys',
  templateUrl: './ansys.component.html',
  styleUrls: ['./ansys.component.scss']
})
export class AnsysComponent implements OnInit {

  ansysLicenseData: any = [];

  constructor(private http: HttpClient) { }

  ngOnInit() {
    this.http.get('assets/license_files/ansys_output_users.json').subscribe((res) => {
      this.ansysLicenseData = res;
    });

  }

}
