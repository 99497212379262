import {trigger, style, state, transition, animate, query} from '@angular/animations';

export const slide = trigger('slide', [
  state('flyIn', style({height: '*', opacity: 1, transform: 'scale(1)'})),
  transition(':enter', [
    style({height: 0, opacity: 0, transform: 'scale(0)'}),
    animate('.2s .2s ease-in')
    ]),
  transition(':leave', [animate('0.2s ease-out', style({opacity: 0, transform: 'translateX(100%)'}))]),
]);
