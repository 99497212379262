import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AfasDataService {

  httpOptions = {
    headers: new HttpHeaders({
      'content-type': 'application/json',
      Authorization: environment.token,
    }),
  };

  constructor(private http: HttpClient) { }

  public async getAfasData(user) {
    console.log('getting afas data');
    this.http.get(environment.getEmployeesUrl + user + '&operatortypes=1',
      this.httpOptions
    ).subscribe( (result: any) => {
      const userData = result.rows[0];
      sessionStorage.setItem('userInternalId', userData.personId);
      sessionStorage.setItem('userEmail', userData.mail);
      sessionStorage.setItem('userManager', userData.Manager);
      sessionStorage.setItem('userFunctionId', userData.functionId);
      sessionStorage.setItem('userFuntionName', userData.functionName);

      this.getContactData(sessionStorage.userInternalId, environment.getContacts);
    });
  }

  public async getContactData(user, afaslink) {
    const link = afaslink + user + '&operatortypes=1';
    await this.http.get(link, this.httpOptions).subscribe((result: any) => {
      console.log(result.rows[0]);
      sessionStorage.setItem('userExternalId', result.rows[0].userExternalId);

    });
  }
}

