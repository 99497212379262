<link rel="stylesheet" href="twd-search.component.css" class="rel">

<!-- <div *ngIf="authService.authenticated" class="row" onload="Afterload();"> -->
<div class="row" onload="Afterload();">
  <div style="width: 100%;">
    <!-- title card -->
    <div class="col-12">
      <div class="card main-card mb-3">
        <div class="search-card-body">
          <h5 class="search-card-title col-sm-4">Internal server searcher</h5>
          <div class="col-sm-4">
            <mat-form-field class="searchBar">
              <mat-label>Search</mat-label>
              <input matInput #query [(ngModel)]='searchQuery' (keyup.enter)="onPageChange($event)">
            </mat-form-field>
            <mat-radio-group class="searchFileContent" [(ngModel)]="searchFileContent" (change)="onPageChange($event)">
              <!-- Add BELOW for button search in title or search in content -->
              <!-- <mat-radio-button *ngFor="let searchContent of searchContents" [checked]="searchContent === 'Search in title'" [value]="searchContent">{{searchContent}}</mat-radio-button> -->
            </mat-radio-group>
          </div>
        </div>
      </div>
    </div>

    <mat-tab-group mat-align-tabs="start" (selectedTabChange)="onTabLoad($event)" [selectedIndex]="6">
<!--      procedures          -->
<!--      <mat-tab label="Procedures" >-->
<!--        <div class="row">-->

<!--          <div class="col-12">-->
<!--            <div class="card main-card mb8">-->
<!--              <div class="result-card-body">-->
<!--                <div id="result">-->
<!--                  <div id="navigation">-->

<!--                    &lt;!&ndash; <mat-paginator id="pager" class="modal-3"></mat-paginator> &ndash;&gt;-->
<!--                    &lt;!&ndash; <div id="pager-header"></div> &ndash;&gt;-->


<!--                  </div>-->

<!--                  <div class="col" *ngFor="let document of dataSource.response.docs">-->
<!--                    <mat-card style="box-shadow: none;" *ngIf="document.doc_type === 'Procedures'">-->
<!--                      <div >-->
<!--                        <div id="imagePaceholder">-->
<!--                          <img class="pdfImage" mat-card-image src={{document.doc_image_path}}>-->
<!--                        </div>-->
<!--                        <div class="resultText">-->
<!--                          <h6>{{document.doc_name}}</h6>-->
<!--                          <p>Document type: {{document.doc_type}}</p>-->
<!--                          <p>Last Modified: {{document.doc_last_mod_date | date: 'dd-MM-yyyy'}}</p>-->

<!--                          <p>Views: {{document.doc_views}}</p>-->
<!--                        </div>-->
<!--                        <div class="pdfButton">-->
<!--                          <button mat-stroked-button color="primary" [cdkCopyToClipboard]="document.doc_location" matTooltip="Copy path to clipboard" style="margin-right: 5px">-->
<!--                            <mat-icon class="copyIcon">file_copy</mat-icon>-->
<!--                          </button>-->
<!--                          <button mat-stroked-button color="primary" class="twd_btn"-->
<!--                          (click)="openDocument(document.id)">-->
<!--                            <a href={{document.doc_web_location}} target="_blank">Open File</a>-->
<!--                          </button>-->

<!--                        </div>-->
<!--                      </div>-->
<!--                      <mat-divider style="margin-top: 1%;"></mat-divider>-->
<!--                    </mat-card>-->

<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="col-12">-->
<!--            <div class="card main-card left-filter-pane mb4">-->
<!--              <div class="left-card-body">-->
<!--                <mat-paginator class="col"-->
<!--                               [length]="dataSource.response.numFound"-->
<!--                               [pageSize]="pageSize"-->
<!--                               [pageSizeOptions]="pageSizeOptions"-->
<!--                               [pageIndex]='0'-->
<!--                               (page)="onPaginationChange($event, 'Procedures')">-->
<!--                </mat-paginator>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </mat-tab>-->
<!--&lt;!&ndash;      templates           &ndash;&gt;-->
<!--      <mat-tab label="Templates">-->
<!--        <div class="row">-->

<!--          <div class="col-12">-->
<!--            <div class="card main-card mb8">-->
<!--              <div class="result-card-body">-->
<!--                <div id="result">-->
<!--                  <div id="navigation">-->

<!--                    &lt;!&ndash; <mat-paginator id="pager" class="modal-3"></mat-paginator> &ndash;&gt;-->
<!--                    &lt;!&ndash; <div id="pager-header"></div> &ndash;&gt;-->


<!--                  </div>-->
<!--                  <div class="col" *ngFor="let document of dataSource.response.docs">-->
<!--                    <mat-card style="box-shadow: none;" *ngIf="document.doc_type === 'Templates'">-->
<!--                      <div >-->
<!--                        <div id="imagePaceholder">-->
<!--                          <img class="pdfImage" mat-card-image src={{document.doc_image_path}}>-->
<!--                        </div>-->
<!--                        <div class="resultText">-->
<!--                          <h6>{{document.doc_name}}</h6>-->
<!--                          <p>Document type: {{document.doc_type}}</p>-->
<!--                          <p>Last Modified: {{document.doc_last_mod_date | date: 'dd-MM-yyyy'}}</p>-->

<!--                          <p>Views: {{document.doc_views}}</p>-->
<!--                        </div>-->
<!--                        <div class="pdfButton">-->
<!--                          <button mat-stroked-button color="primary" [cdkCopyToClipboard]="document.doc_location" matTooltip="Copy path to clipboard" style="margin-right: 5px">-->
<!--                            <mat-icon class="copyIcon">file_copy</mat-icon>-->
<!--                          </button>-->
<!--                          <button mat-stroked-button color="primary" class="twd_btn"-->
<!--                          (click)="openDocument(document.id)">-->
<!--                            <a href={{document.doc_web_location}} target="_blank">Open File</a>-->
<!--                          </button>-->

<!--                        </div>-->
<!--                      </div>-->
<!--                      <mat-divider style="margin-top: 1%;"></mat-divider>-->
<!--                    </mat-card>-->

<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="col-12">-->
<!--            &lt;!&ndash;            pagination&ndash;&gt;-->
<!--            <div class="card main-card left-filter-pane mb4">-->
<!--              <div class="left-card-body">-->
<!--                <mat-paginator class="col"-->
<!--                               [length]="dataSource.response.numFound"-->
<!--                               [pageSize]="pageSize"-->
<!--                               [pageSizeOptions]="pageSizeOptions"-->
<!--                               [pageIndex]='0'-->
<!--                               (page)="onPaginationChange($event, 'Templates')">-->
<!--                </mat-paginator>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </mat-tab>-->
<!--&lt;!&ndash;      literature          &ndash;&gt;-->
<!--      <mat-tab label="Literature">-->
<!--&lt;!&ndash;        search file content&ndash;&gt;-->
<!--        <div class="row">-->
<!--&lt;!&ndash;        results pane&ndash;&gt;-->
<!--          <div class="col-12">-->
<!--            <div class="card main-card mb8">-->
<!--              <div class="result-card-body">-->
<!--                <div id="result">-->
<!--                  <div id="navigation">-->

<!--                    &lt;!&ndash; <mat-paginator id="pager" class="modal-3"></mat-paginator> &ndash;&gt;-->
<!--                    &lt;!&ndash; <div id="pager-header"></div> &ndash;&gt;-->


<!--                  </div>-->
<!--                  <div class="col" *ngFor="let document of dataSource.response.docs">-->
<!--                    <mat-card style="box-shadow: none;" *ngIf="document.doc_type === 'Literature'">-->
<!--                      <div >-->
<!--                        <div id="imagePaceholder">-->
<!--                          <img class="pdfImage" mat-card-image src={{document.doc_image_path}}>-->
<!--                        </div>-->
<!--                        <div class="resultText">-->
<!--                          <h6>{{document.doc_name}}</h6>-->
<!--                          <p>Document type: {{document.doc_type}}</p>-->
<!--                          <p>Last Modified: {{document.doc_last_mod_date | date: 'dd-MM-yyyy'}}</p>-->

<!--                          <p>Views: {{document.doc_views}}</p>-->
<!--                        </div>-->
<!--                        <div class="pdfButton">-->
<!--                          <button mat-stroked-button color="primary" [cdkCopyToClipboard]="document.doc_location" matTooltip="Copy path to clipboard" style="margin-right: 5px">-->
<!--                            <mat-icon class="copyIcon">file_copy</mat-icon>-->
<!--                          </button>-->
<!--                          <button mat-stroked-button color="primary" class="twd_btn"-->
<!--                                  (click)="openDocument(document.id)">-->
<!--                            <a href={{document.doc_web_location}} target="_blank">Open File</a>-->
<!--                          </button>-->

<!--                        </div>-->
<!--                      </div>-->
<!--                      <mat-divider style="margin-top: 1%;"></mat-divider>-->
<!--                    </mat-card>-->

<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--&lt;!&ndash;        pagination&ndash;&gt;-->
<!--          <div class="col-12">-->
<!--            <div class="card main-card left-filter-pane mb4">-->
<!--              <div class="left-card-body">-->
<!--                <mat-paginator class="col"-->
<!--                               [length]="dataSource.response.numFound"-->
<!--                               [pageSize]="pageSize"-->
<!--                               [pageSizeOptions]="pageSizeOptions"-->
<!--                               [pageIndex]='0'-->
<!--                               (page)="onPaginationChange($event, 'Templates')">-->
<!--                </mat-paginator>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </mat-tab>-->
<!--      standard and codes  -->
<!--      <mat-tab label="Standard & codes">-->
<!--        <div class="row">-->
<!--&lt;!&ndash;      results pane &ndash;&gt;-->
<!--          <div class="col-12">-->
<!--            <div class="card main-card mb8">-->
<!--              <div class="result-card-body">-->
<!--                <div id="result">-->
<!--                  <div id="navigation">-->

<!--                    &lt;!&ndash; <mat-paginator id="pager" class="modal-3"></mat-paginator> &ndash;&gt;-->
<!--                    &lt;!&ndash; <div id="pager-header"></div> &ndash;&gt;-->


<!--                  </div>-->
<!--                  <div class="col" *ngFor="let document of dataSource.response.docs">-->
<!--                    <mat-card style="box-shadow: none;" *ngIf="document.doc_type === 'Standard and codes'">-->
<!--                      <div >-->
<!--                        <div id="imagePaceholder">-->
<!--                          <img class="pdfImage" mat-card-image src={{document.doc_image_path}}>-->
<!--                        </div>-->
<!--                        <div class="resultText">-->
<!--                          <h6>{{document.doc_name}}</h6>-->
<!--                          <p>Document type: {{document.doc_type}}</p>-->
<!--                          <p>Last Modified: {{document.doc_last_mod_date | date: 'dd-MM-yyyy'}}</p>-->

<!--                          <p>Views: {{document.doc_views}}</p>-->
<!--                        </div>-->
<!--                        <div class="pdfButton">-->
<!--                          <button mat-stroked-button color="primary" [cdkCopyToClipboard]="document.doc_location" matTooltip="Copy path to clipboard" style="margin-right: 5px">-->
<!--                            <mat-icon class="copyIcon">file_copy</mat-icon>-->
<!--                          </button>-->
<!--                          <button mat-stroked-button color="primary" class="twd_btn"-->
<!--                                  (click)="openDocument(document.id)">-->
<!--                            <a href={{document.doc_web_location}} target="_blank">Open File</a>-->
<!--                          </button>-->

<!--                        </div>-->
<!--                      </div>-->
<!--                      <mat-divider style="margin-top: 1%;"></mat-divider>-->
<!--                    </mat-card>-->

<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--&lt;!&ndash;      pagination &ndash;&gt;-->
<!--          <div class="col-12">-->
<!--            <div class="card main-card left-filter-pane mb4">-->
<!--              <div class="left-card-body">-->
<!--                <mat-paginator class="col"-->
<!--                               [length]="dataSource.response.numFound"-->
<!--                               [pageSize]="pageSize"-->
<!--                               [pageSizeOptions]="pageSizeOptions"-->
<!--                               [pageIndex]='0'-->
<!--                               (page)="onPaginationChange($event, 'Standard and codes')">-->
<!--                </mat-paginator>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </mat-tab>-->
<!--      Projects Folder     -->
      <mat-tab label="*">
        <ng-template mat-tab-label>
          Project Title ({{projectData.response.numFound}})
        </ng-template>
        <div class="row">
          <div class="col-12">
            <div class="card main-card mb8">
              <div class="result-card-body">
                <div id="result">
                  <div id="navigation"></div>
                  <div class="col" *ngFor="let document of projectData.response.docs">
                    <!-- <div *ngIf="i % 2 !=1"> -->
                      <mat-card style="box-shadow: none;">
                        <div>
                          <div id="imagePaceholder">
<!--                            <div class="folderIconImage" mat-card-image></div>-->
                            <img class="folderIconImage" src="../../../assets/folder_document_icon.png">
                          </div>
                          <div class="resultText">
                            <h6>{{document.project_name}}</h6>
                            <p>Year: {{document.project_year}}</p>
                            <p>Office: {{document.project_office}}</p>
                          </div>

                          <div class="pdfButton">
                            <button mat-stroked-button class="twd_btn"
                            [cdkCopyToClipboard]="'file:///'+document.project_server_location">Copy Path To Clipboard
                            </button>
                          </div>

                        </div>
                        <mat-divider style="margin-top: 1%;"></mat-divider>
                      </mat-card>
                    <!-- </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12">
            <!-- document types -->
            <div class="card main-card left-filter-pane mb4">
              <div class="left-card-body">
                <mat-paginator class="col custom-paginator"
                               [length]="projectData.response.numFound"
                               [pageSize]="pageSize"
                               [pageSizeOptions]="pageSizeOptions"
                               [pageIndex]='0'
                               (page)="onProjectPaginationChange($event)">
                </mat-paginator>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
     <!-- advanced search     -->
     <!-- <mat-tab label="Advanced Search"> -->
<!--          &lt;!&ndash; filter panes &ndash;&gt;-->
<!--        <div class="row">-->
<!--          &lt;!&ndash; current selection &ndash;&gt;-->

<!--            <div class="col-4">-->

<!--            &lt;!&ndash; Pagination &ndash;&gt;-->
<!--            <div class="card main-card left-filter-pane mb4">-->
<!--              <div class="left-card-body">-->
<!--                <mat-paginator class="col"-->
<!--                  [length]="dataSource.response.numFound"-->
<!--                  [pageSize]="pageSize"-->
<!--                  [pageSizeOptions]="pageSizeOptions"-->
<!--                  [pageIndex]='0'-->
<!--                  (page)="onPageChange($event)">-->
<!--                </mat-paginator>-->
<!--              </div>-->
<!--            </div> -->


<!--            &lt;!&ndash; involved &ndash;&gt;-->
<!--&lt;!&ndash;              <div class="card main-card left-filter-pane mb4">&ndash;&gt;-->
<!--&lt;!&ndash;                <div class="left-card-body">&ndash;&gt;-->
<!--&lt;!&ndash;                  <p class="left-card-title">Involved</p>&ndash;&gt;-->
<!--&lt;!&ndash;                  <mat-form-field appearance="fill" style="width: 100%;">&ndash;&gt;-->
<!--&lt;!&ndash;                    <mat-label>Choose Involved Person</mat-label>&ndash;&gt;-->
<!--&lt;!&ndash;                    <mat-select [formControl]="involved" multiple (selectionChange)="onPageChange($event)">&ndash;&gt;-->
<!--&lt;!&ndash;                      <mat-option *ngFor="let involved of involvedList" [value]="involved">{{involved}}</mat-option>&ndash;&gt;-->
<!--&lt;!&ndash;                    </mat-select>&ndash;&gt;-->
<!--&lt;!&ndash;                  </mat-form-field>&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->


<!--            &lt;!&ndash; document types &ndash;&gt;-->
<!--              <div class="card main-card left-filter-pane mb4">-->
<!--                <div class="left-card-body">-->
<!--                  <p class="left-card-title">Document Types</p>-->
<!--                  <mat-form-field appearance="fill" style="width: 100%;">-->
<!--                    <mat-label>Choose Document Type</mat-label>-->
<!--                    <mat-select [formControl]="docType" multiple (selectionChange)="onPageChange($event)">-->
<!--                      <mat-option *ngFor="let type of documentTypeList" [value]="type">{{type}}</mat-option>-->
<!--                    </mat-select>-->
<!--                  </mat-form-field>-->
<!--                </div>-->
<!--              </div>-->


<!--            &lt;!&ndash; date range &ndash;&gt;-->
<!--              <div class="card main-card left-filter-pane mb4">-->
<!--                <div class="left-card-body">-->
<!--                  <p class="left-card-title">Date Range</p>-->
<!--                  <mat-form-field appearance="fill" style="width: 100%;">-->
<!--                    <mat-label>Choose a date range</mat-label>-->
<!--                    <mat-date-range-input [formGroup]="range" [rangePicker]="picker">-->
<!--                      <input matStartDate formControlName="start" placeholder="Start date">-->
<!--                      <input matEndDate formControlName="end" placeholder="End date"-->
<!--                      (dateInput)="onPageChange($event)">-->
<!--                    </mat-date-range-input>-->
<!--                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>-->
<!--                    <mat-date-range-picker #picker></mat-date-range-picker>-->

<!--                    <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>-->
<!--                    <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>-->
<!--                  </mat-form-field>-->
<!--                </div>-->
<!--              </div>-->

<!--              &lt;!&ndash; search Location &ndash;&gt;-->
<!--&lt;!&ndash;              <div class="card main-card left-filter-pane mb4">&ndash;&gt;-->
<!--&lt;!&ndash;                <div class="left-card-body">&ndash;&gt;-->
<!--&lt;!&ndash;                  <p class="left-card-title">Search Location</p>&ndash;&gt;-->
<!--&lt;!&ndash;                  <mat-form-field appearance="fill" style="width: 100%;">&ndash;&gt;-->
<!--&lt;!&ndash;                    <mat-label>Choose Search Location</mat-label>&ndash;&gt;-->
<!--&lt;!&ndash;                    &lt;!&ndash;  &ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                    <mat-select [formControl]="docLocation" (selectionChange)="onPageChange($event)">&ndash;&gt;-->
<!--&lt;!&ndash;                      <mat-option *ngFor="let type of documentLocationList" [value]="type">{{type}}</mat-option>&ndash;&gt;-->
<!--&lt;!&ndash;                    </mat-select>&ndash;&gt;-->
<!--&lt;!&ndash;                  </mat-form-field>&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->

<!--            </div>-->

<!--            &lt;!&ndash; result pane &ndash;&gt;-->
<!--            <div class="col-8">-->
<!--              <div class="card main-card mb8">-->
<!--                <div class="result-card-body">-->
<!--                  <div id="result">-->
<!--                    <div id="navigation">-->

<!--                      &lt;!&ndash; <mat-paginator id="pager" class="modal-3"></mat-paginator> &ndash;&gt;-->
<!--                      &lt;!&ndash; <div id="pager-header"></div> &ndash;&gt;-->


<!--                    </div>-->
<!--                    <div class="col">-->
<!--                      <mat-card style="box-shadow: none;" *ngFor="let document of dataSource.response.docs">-->
<!--                        <div id="imagePaceholder">-->
<!--                          <img class="pdfImage" mat-card-image src={{document.doc_image_path}}>-->
<!--                        </div>-->
<!--                        <div class="resultText">-->
<!--                          <h6>{{document.doc_name}}</h6>-->
<!--                          <p>Document type: {{document.doc_ext}}</p>-->
<!--                          <p>Last Modified: {{document.doc_last_mod_date | date: 'dd-MM-yyyy'}}</p>-->

<!--                          <p>Views: {{document.doc_views}}</p>-->
<!--                          <p>{{document.doc_type}}</p>-->
<!--                        </div>-->
<!--                        <div class="pdfButton">-->
<!--                          <button mat-stroked-button color="primary" [cdkCopyToClipboard]="document.doc_location" matTooltip="Copy path to clipboard" style="margin-right: 5px">-->
<!--                            <mat-icon class="copyIcon">file_copy</mat-icon>-->
<!--                          </button>-->
<!--                          <button mat-stroked-button color="primary" class="twd_btn"-->
<!--                          (click)="openDocument(document.id)">-->
<!--                          <a href={{document.doc_web_location}} target="_blank">Open File</a>-->
<!--                        </button>-->

<!--                        </div>-->
<!--                        <mat-divider style="margin-top: 1%;"></mat-divider>-->
<!--                      </mat-card>-->

<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--        </div>-->
<!--      </mat-tab> -->

    </mat-tab-group>



  </div>
</div>

<iframe src='' width='80%' height='800px' frameborder='0'></iframe>

