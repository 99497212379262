import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators, AbstractControl} from '@angular/forms';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {PhoneListService} from '../phone-list.service';

@Component({
  selector: 'app-phone-list-dialog',
  templateUrl: './phone-list-dialog.component.html',
  styleUrls: ['./phone-list-dialog.component.scss']
})
export class PhoneListDialogComponent implements OnInit {
  phoneDetailForm: FormGroup;
  true = 'true';
  false = 'False';
  submitted = false;

  panelOpenState = false;
  openedFromAddBtn: boolean;

  constructor(
    private phoneListService: PhoneListService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder) {

    this.phoneDetailForm = this.fb.group({
      sequenceNumber: [data.phone.Volgnummer],
      phoneNumber: [data.phone.phoneNumber, [Validators.required, Validators.maxLength(3)]],
      initials: [data.phone.initials],
      pcNumber: [data.phone.pcNumber, [Validators.maxLength(12), Validators.required]],
      firstName: [data.phone.firstName],
      lastName: [data.phone.lastName],
      workMobile: [data.phone.workMobile]
    });
  }

  ngOnInit(): void {
    console.log('phoneListDialogData: ', this.data);
    this.openedFromAddBtn = this.data.openedFromAdd;
    console.log('opened from add btn?', this.openedFromAddBtn);
  }

  get f() { return this.phoneDetailForm.controls; }

  onSubmit(): void {
    console.log(this.phoneDetailForm.value);
    this.submitted = true;

    if (this.phoneDetailForm.invalid === true) {
      return;
    } else if (this.openedFromAddBtn === false && this.phoneDetailForm.invalid === false) {
      // if item is opened from edit button
      this.phoneListService.updatePhoneListItem(this.phoneDetailForm.value);
    } else if (this.openedFromAddBtn === true && this.phoneDetailForm.invalid === false) {
      // if item is opened from Add button
      this.phoneListService.addPhoneListItem(this.phoneDetailForm.value);
    }
  }

}
