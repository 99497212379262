import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { SnackService } from '../snack/snack.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(
    private readonly snackBar: MatSnackBar,
    private readonly zone: NgZone,
    private service: SnackService,
  ) {
  }

  default(message: string) {
    this.snackBar.open(message, 'x', {
      duration: 4000,
      panelClass: 'default-notification-overlay'
    });
  }

  info(message: string) {
    this.snackBar.open(message, 'x', {
      duration: 4000,
      panelClass: 'info-notification-overlay'
    });
  }

  success(message: string) {
    this.snackBar.open(message, 'x', {
      duration: 4000,
      panelClass: ['success-notification-overlay']
    });
  }

  error(message: string) {
    this.snackBar.open(message, 'x', {
      duration: 5000,
      panelClass: ['error-notification-overlay']
    });
  }

  sendSnack(message: string, duration: any) {
    this.service.newAlert(message, duration);
  }

  private show(message: string, configuration: MatSnackBarConfig) {
    this.zone.run(() => this.snackBar.open(message));
  }
}
