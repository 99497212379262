import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { GetPhonelistService } from './get-phonelist.service';
import { PhoneList, Users } from '../../../../models/phonelist-interface';

@Component({
  selector: 'app-phonelist',
  templateUrl: './phonelist.component.html',
  styleUrls: ['./phonelist.component.css']
})
export class PhonelistComponent implements OnInit {

  constructor(private http: HttpClient,
              private getPhonelistService: GetPhonelistService) { }

  displayedColumns: string[] = ['Photo', 'firstName', 'lastName', 'initials', 'phoneNumber', 'workMobile'];
  dataSource: PhoneList[] = [];
  userSource: Users[] = [];
  resultsLength = 0;

  // set isloading to false
  isLoadingResults = false;

  isRateLimitReached = false;

  tableDataSource: MatTableDataSource<PhoneList>;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  //
    async run() {
  //
  }

  ngOnInit() {
    // this.isLoadingResults = true;
    console.log(this.isLoadingResults);
    // this.tableDataSource.sort = this.sort;
    this.getPhonelistService.get_PhoneList_AFAS().subscribe((result) => {
      this.dataSource = result.rows;
      this.tableDataSource = new MatTableDataSource(this.dataSource);

      // set correct photo to user using data.twd.nl
      for (const user of this.dataSource) {
        user.Photo = '/assets/avatars/' + user.initials + '.png';
      }

      // this.isLoadingResults = false;
      console.log(this.isLoadingResults);
    });
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.tableDataSource.filter = filterValue.trim().toLowerCase();
  }

}


//
