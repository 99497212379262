<mat-dialog-content class="mat-typography">

  <form [formGroup]="phoneDetailForm" (ngSubmit)="onSubmit()">
<!--    phoneNumber field-->
    <div class="form-group row">
      <div class="col-2">
        <label for="phoneNumber">Phone Number</label>
      </div>
      <div class="col-10">
        <input
          class="form-control"
          type="text"
          formControlName="phoneNumber"
          [ngClass]="{ 'is-invalid': submitted && f.phoneNumber.errors }"
        />
        <div *ngIf="submitted && f.phoneNumber.errors" class="invalid-feedback">
          <div *ngIf="f.phoneNumber.errors.required"> Number is required</div>
          <div *ngIf="f.phoneNumber.errors.maxlength"> Number is too long</div>
        </div>
      </div>
    </div>
  <!--    pc number field-->
    <div class="form-group row">
      <div class="col-2">
        <label for="pcNumber">Workstation Number</label>
      </div>
      <div class="col-10">
        <input
          type="text"
          class="form-control"
          formControlName="pcNumber"
          [ngClass]="{ 'is-invalid': submitted && f.pcNumber.errors }"
        />
        <div *ngIf="submitted && f.pcNumber.errors" class="invalid-feedback">
          <div *ngIf="f.pcNumber.errors.required"> Workstation number is required</div>
          <div *ngIf="f.pcNumber.errors.maxlength"> Workstation number must not exceed 8 characters</div>
        </div>
      </div>
    </div>
<!--    initials field-->
    <div class="form-group row">
      <div class="col-2">
        <label for="initials">Initials</label>
      </div>
      <div class="col-10">
        <input type="text" class="form-control" formControlName="initials">
      </div>
    </div>
<!--    first name-->
    <div class="form-group row">
      <div class="col-2">
        <label for="firstName">First Name</label>
      </div>
      <div class="col-10">
        <input type="text" class="form-control" formControlName="firstName">
      </div>
    </div>
<!--    last name-->
    <div class="form-group row">
      <div class="col-2">
        <label for="lastNAme">Last Name</label>
      </div>
      <div class="col-10">
        <input type="text" class="form-control" formControlName="lastName">
      </div>
    </div>
<!--    wordk mobile-->
    <div class="form-group row">
      <div class="col-2">
        <label for="workMobile">Work Number</label>
      </div>
      <div class="col-10">
        <input type="text" class="form-control" formControlName="workMobile">
      </div>
    </div>

<!--    submit button-->
    <mat-dialog-actions>
      <button mat-button [mat-dialog-close]="true" class="twd_btn_warning">Cancel</button>
      <button mat-button type="submit" class="twd_btn">Save</button>
    </mat-dialog-actions>

  </form>

<!--  preview form-->
<!--  <pre>{{ phoneDetailForm.value | json }}</pre>-->

</mat-dialog-content>
