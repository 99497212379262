import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-autodesk',
  templateUrl: './autodesk.component.html',
  styleUrls: ['./autodesk.component.css']
})
export class AutodeskComponent implements OnInit {

  constructor(
    private http: HttpClient
  ) { }

  autodeskLicenseData: any = [];

  ngOnInit() {

    this.http.get('assets/license_files/autodesk_output_users.json').subscribe((res) => {
      this.autodeskLicenseData = res;
    });

    for (const session of this.autodeskLicenseData) {
      const sessionStart = Date.parse(this.autodeskLicenseData.sessionStart);
      console.log('sessionStart: ', sessionStart);
      session.sessionDuration = Date.now() - sessionStart;
      console.log(session.sessionDuration);
    }

    console.log(this.autodeskLicenseData);


  }

}
