import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AttendeeDataResponse } from '../../models/workshop_links';
import {NotificationService} from '../../notification-service/notification.service';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class GetWorkshopDataService {

//
public updateEmployeesUrl = environment.updateEmployeesUrl;
public token = environment.token;

// create http options for update data
httpOptions = {
  headers: new HttpHeaders({ 'content-type': 'application/json', Authorization: this.token })
};

constructor(
  private httpClient: HttpClient,
  private notificationService: NotificationService,
) { }

  // Get emp info based on attendance
async getFilteredAttendees(workshop) {
  return this.httpClient.get<AttendeeDataResponse>(
    environment.workshopGetFilteredAttendeesUrl + workshop + '&operatortypes=1',
    this.httpOptions
  );
}

// function to determine who was at the workshop
updateAttendeeAfasData(employees, workshopId) {

  // create empty list for the selected employee
  const attendedEmployees = [];

  // append the [Zoeknaam] key to the list of attended employees
  for (const attendedEployee of employees) {
    console.log('selected employees: ', attendedEployee);
    attendedEmployees.push(`\"${attendedEployee.id}\"`);
    console.log(attendedEmployees);
  }

  // create JSON data as body for afas
  const dataForUpdate = {
    KnCustomK07: {
      Element: {
        Fields: {
          SqNo: workshopId,
          U2E493E82473D46954781E900BDF28DDF: '[' + attendedEmployees + ']',
        }
      }
    }
  };

  console.log('update attendees data: ', dataForUpdate);

  // send data to afas
  this.httpClient.put(this.updateEmployeesUrl, JSON.stringify(dataForUpdate), this.httpOptions)
  .subscribe();
  // console.log(data_for_update)
}

deleteWorkshopFromAfas(workshopId) {
  const updateUrl = this.updateEmployeesUrl + '/KnCustomK07/SqNo/' + workshopId;
  this.httpClient.delete(updateUrl, this.httpOptions)
    .subscribe(
      data => this.notificationService.success('successfully updated'),
      error => this.notificationService.error('update failed: ' + error.error.externalMessage),
    );
}

GetAfasWorkshops(): Observable<any> {
  return this.httpClient.get<any>(environment.workshopsGetUrl, this.httpOptions);
}

GetAfasWorkshopAttendees(): Observable<any> {
  return this.httpClient.get<any>(environment.workshopsGetAttendeesUrl, this.httpOptions);
}

}

