import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DashboardAuthService {

  constructor(private http: HttpClient) { }

  public getDashboardAuthentication()
  {
    return this.http.get('https://twdarchivestore.file.core.windows.net/archive/Hosted_Files/dashboard_authentication/authentication.json?sv=2021-06-08&ss=bfqt&srt=sco&sp=r&se=2024-12-21T22:21:47Z&st=2022-12-21T14:21:47Z&spr=https&sig=f13x6nxMBBtVbwZQSyowYzbcdHNNAsyQV4r7%2FWZX94s%3D');
  }
}
