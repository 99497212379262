import { Injectable } from '@angular/core';
import { AuthService } from '../../auth.service';
import { GraphService } from '../../graph.service';
import { AlertsService } from '../../alerts/alerts.service';
import { Client } from '@microsoft/microsoft-graph-client';

@Injectable({
  providedIn: 'root'
})
export class AddToCalendarService {

  constructor(public authService: AuthService,
              public graphService: GraphService) { }


  private graphClient = Client.init({
    authProvider: async (done) => {
      // Get the token from the auth service
      const token = sessionStorage.accessToken;

      if (token) {
        done(null, token);
      } else {
        done('Could not get an access token', null);
      }
    }
  });

  async addToCal(event){

    const eventBody = {
      subject: event.workshopTitle,
      start: {
        dateTime: (event.workshopDate).substring(0, 10) + 'T' + event.workshopStarttime,
        timeZone: 'Europe/Berlin'
      },
      end: {
        dateTime: (event.workshopDate).substring(0, 10) + 'T' + event.workshopEndtime,
        timeZone: 'Europe/Berlin'
      }
    };

    console.log('eventbody to graph calendar: ', eventBody);
    // WRITE TO CALENDAR
    const result = await this.graphClient.api('/me/events').post(eventBody);

    console.log('graph calendar response: ', result);
}

}
