import { Component, OnInit} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AuthService } from 'src/app/auth.service';

@Component({
  selector: 'app-hourapp',
  templateUrl: './hourapp.component.html',
  styleUrls: ['./hourapp.component.css']
})
export class HourappComponent implements OnInit {
  name = 'Set iframe source';
  url = 'https://chronos.twd.nl/';
  urlSafe: SafeResourceUrl;

  constructor(
    public sanitizer: DomSanitizer,
    public authService: AuthService
    ) { }

  ngOnInit() {
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }
}
