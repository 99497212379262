// tslint:disable: max-line-length
import {HttpClient} from '@angular/common/http';
import {Component, ViewChild, AfterViewInit, Pipe, PipeTransform} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {of as observableOf, Subscription, Observable} from 'rxjs';
import {catchError, map, } from 'rxjs/operators';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import {GenFinMutatiesService} from './GenFinMutaties.service';
import { GenBalieDirectService } from './GenBalieDirect.service';
import { GenExportService } from './GenExport.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogModel, ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { GenHTMLRegelsService } from './GenHTMLRegels.service';
import { BalieIssue, FactuurModel, InvoicesWithVerdichteRegelsCollections, VerdichteRegel, HttpResponseFacturen, HttpResponseBalie } from './main_models';
import { MatTableDataSource } from '@angular/material/table';
import { BalieLogComponent } from '../Balie-Log/Balie-Log.component';
import { v4 as uuid } from 'uuid';
import { DatePipe } from '@angular/common';


/**
 * @title Table retrieving data through HTTP
 */
@Component({
  selector: 'table-http-example',
  styleUrls: ['table-http-example.css'],

  templateUrl: 'table-http-example-test.html',
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class InvoiceTableComponent implements AfterViewInit {

  // COLLUMNS TO BE DISPLAYED
  displayedColumns = ['select', 'Factuurnummer', 'Factuurdatum', 'Project_number', 'Factuurtotaal', 'Eindklant', 'Invoiceweek', 'Exported'];
  balieColumns = [
    // { columnDef: 'Factuurnummer', header: 'Factuur', cell: (BalieIssue: BalieIssue) => `${BalieIssue.Factuurnummer}` },
    { columnDef: 'Itemcode', header: 'Soort', cell:               (BalieIssue_item: BalieIssue) => `${BalieIssue_item.Itemcode}` },
    { columnDef: 'Aantal_eenheden', header: 'Aantal', cell:       (BalieIssue_item: BalieIssue) => `${BalieIssue_item.Aantal_eenheden}` },
    { columnDef: 'Prijs_per_eenheid', header: 'Prijs p.e.', cell: (BalieIssue_item: BalieIssue) => `${BalieIssue_item.Prijs_per_eenheid}` },
    { columnDef: 'Regelbedrag', header: 'Bedrag', cell:           (BalieIssue_item: BalieIssue) => `${BalieIssue_item.Regelbedrag}` },
  ];
  balieDisplayColumns = this.balieColumns.map(c => c.columnDef );
  result = '';

  // DATABASES FOR FINANCIELE MUTAITES
  invoice_data: FactuurModel[] = [];
  expandedData: FactuurModel[] | null;
  selection = new SelectionModel<FactuurModel>(true, []);
  invoice_data$: Observable<HttpResponseFacturen>;
  invoice_data_subscription: Subscription;
  table_datasource: MatTableDataSource<FactuurModel>;

  // DATABASES FOR BALIEDIRECT
  balie_data: BalieIssue[] = [];
  expandedBalieData: BalieIssue[] | null;
  balie_data$: Observable<HttpResponseBalie>;
  balie_data_subscription: Subscription;

  // Subscription to factuurdict
  factuurdictSubscription: Subscription;
  factuurdictlocalcopy = {} as InvoicesWithVerdichteRegelsCollections;
  selectionBalie = new SelectionModel<VerdichteRegel>(true, []);

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  ExportedValue = [true, false];
  public Invoiceweek: any = {};

  // GENERATE CURRENT WEEK NUMBER
  randomNumber = uuid();
  today = new Date();
  public formattedWeekNumber = this.datepipe.transform(this.today, 'ww');
  public formattedYear = this.datepipe.transform(this.today, 'yyyy');
  facturatieWeekNummer = this.formattedYear + '-' + this.formattedWeekNumber;

  // SEND TO AFAS YES OR NO
  send_to_afas: boolean;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;


  constructor(
    private _httpClientBalie: HttpClient,
    private service_fin: GenFinMutatiesService,
    private service_balie: GenBalieDirectService,
    private service_export: GenExportService,
    public dialog: MatDialog,
    private service_GenHtml: GenHTMLRegelsService,
    private BalieLog: BalieLogComponent,
    private datepipe: DatePipe,


    ) {

      // Asign here all observables
      this.invoice_data$ = this.service_fin.GetFinanMutations();
      // this.balie_data$ = this.service_balie.get_intercomp_balies();
    }

  ngAfterViewInit() {

    this.factuurdictSubscription = this.service_GenHtml.InvoicesWithVerdichteRegelsSubject.pipe(
      map(() => {
        this.factuurdictlocalcopy = this.service_GenHtml.InvoicesWithVerdichteRegels_dict;
      })
    ).subscribe();

    // Subscription for the invoice data. This gets triggerd if the invoicedata observable is trigged.
    this.invoice_data_subscription = this.invoice_data$
    .pipe(
      map((httpdata: HttpResponseFacturen) => {
        if (httpdata.rows.length > 0) {
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.invoice_data = httpdata.rows;
          this.resultsLength = this.invoice_data.length;
          this.table_datasource = new MatTableDataSource(this.invoice_data);
          this.table_datasource.paginator = this.paginator;
          this.table_datasource.sort = this.sort;

          // Check if the balie data is already loaded, if yes make the verdichte regels.
          if (this.balie_data) {
            this.Generate_Invoice_data_Table();
          }
        }
      }),
      catchError(() => {
        this.isLoadingResults = false;
        // Catch if the GitHub API has reached its rate limit. Return empty data.
        this.isRateLimitReached = true;
        return observableOf([]);
      })
    )
    .subscribe();

    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

  }



  // CONFIRMATION DIALOG FOR CHANGING STATUS
  confirmDialog(row: any): void {
    const message = `Are you sure you want to do change the status?`;

    const dialogData = new ConfirmDialogModel('Confirm Action', message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      this.result = dialogResult;
      if (dialogResult === true) {
        this.changeAfasExportStatus(row);
      }
    });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    // const SelectedFact$ = this.selection.selected;
    // SelectedFact$.push();
    const numSelected = this.selection.selected.length;
    const numRows = this.invoice_data.length;
    return numSelected === numRows;
  }

  isAllSelectedBalie() {
    const numSelected = this.selectionBalie.selected.length;
    let amount_of_verdichte_regels = 0;
    for (const key of Object.keys(this.factuurdictlocalcopy)) {
      for (const {} of Object.keys(this.factuurdictlocalcopy[key])) {
        amount_of_verdichte_regels += 1;
      }
    }
    // const numRows =  Object.keys(this.factuurdictlocalcopy).length;
    const numRows = amount_of_verdichte_regels;
    return numSelected === numRows;
  }

  // MASTER TOGGLE SELECT ALL FROM BOTH CONNECTORS
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      for (const key of this.invoice_data) {
          this.selection.select(key);
        }
    }

    if (this.isAllSelectedBalie()) {
      this.selectionBalie.clear();
    } else {
      for (const key of Object.keys(this.factuurdictlocalcopy)) {
        for (const key2 of Object.keys(this.factuurdictlocalcopy[key])) {
          this.selectionBalie.select(this.factuurdictlocalcopy[key][key2]);
        }
      }
    }
  }

  // SELECT ONLY ROWS WITH THE SAME FACTUURNUMMER
  regelsToggle(selected_row: FactuurModel) {

    // Check if all verdichte regels are selected
    let all_selected = true;
    for (const key2 of Object.keys(this.factuurdictlocalcopy[selected_row.Factuurnummer])) {
      if (!this.selectionBalie.isSelected(this.factuurdictlocalcopy[selected_row.Factuurnummer][key2])) {
        all_selected = false;
      }
    }

    if (all_selected) {
      for (const key2 of Object.keys(this.factuurdictlocalcopy[selected_row.Factuurnummer])) {
        this.selectionBalie.deselect(this.factuurdictlocalcopy[selected_row.Factuurnummer][key2]);
      }
    } else {

      for (const key2 of Object.keys(this.factuurdictlocalcopy[selected_row.Factuurnummer])) {
        this.selectionBalie.select(this.factuurdictlocalcopy[selected_row.Factuurnummer][key2]);
      }
    }
  }

  // GENEREREN FINANCIELE MUTATIE
  GenFinMutaties() {
    this.service_fin.update_invoices_in_AFAS(this.selection.selected);
  }

  // GENEREREN BALIE DIRECT FACTUUR IF SEND_TO_AFAS TRUE DAN INSCHIETEN ANDERS OPSLAAN NAAR EXCELL
  GenBalieDirect(send_to_afas: boolean) {
    this.service_balie.Send_Baliedirect_to_AFAS(this.selectionBalie.selected, this.selection.selected, this.facturatieWeekNummer, send_to_afas);
  }

  // CHANGE EXPORT STATUS IN AFAS TRUE OR FALSE
  changeAfasExportStatus(row: any) {
    this.service_export.UpdateExport(row);
  }

  // ADD STRING TO INVOICEWEEK COLUMN
  addInvoiceweek(input: any) {

  }

  // Make the Invoice Overiew for the table, with verdichte regels
  Generate_Invoice_data_Table() {
    console.log('Generate verdichte data for table');
    this.service_GenHtml.Generate_Invoicedata_for_table(this.invoice_data);
  }

}
