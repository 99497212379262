import { NgModule} from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TwdSearchComponent } from './Pages/twd-search/twd-search.component';
import { HourappComponent } from './Pages/hourapp/hourapp.component';
import { HomepageComponent } from './Pages/homepage/homepage.component';
import { RoadmapComponent } from './Pages/roadmap/roadmap.component';
import { KbeWikiComponent } from './Pages/wiki/kbe-wiki/kbe-wiki.component';
import { MarineComponent } from './Pages/wiki/marine/marine.component';
import { FinanceappComponent } from './Pages/financeapp/financeapp.component';
import { OrganogramComponent } from './Pages/organogram/organogram.component';
import { Workshopapp_v2Component } from './Pages/workshopapp_v2/workshopapp_v2.component';
import { workshopAppV3Component } from './Pages/workshopapp_v3/workshopapp_v3.component';
import {IctComponent} from './Pages/ict/ict.component';
import {LicensesComponent} from './Pages/licenses/licenses.component';
import {ExperthubComponent} from './Pages/experthub/experthub.component';


const routes: Routes = [
  {
    path: 'apps/search',
    component: TwdSearchComponent,
    data: { extraParameter: '' },
  },
  {
    path: 'apps/hourapp',
    component: HourappComponent,
    data: { extraParameter: '' },
  },
  {
    path: 'home/dashboard',
    component: HomepageComponent,
    data: { extraParameter: '' },
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'knowledgebase/roadmap',
    component: RoadmapComponent,
    data: { extraParameter: '' },
  },
  {
    path: 'apps/experthub',
    component: ExperthubComponent,
    data: { extraParameter: '' },
  },
  {
    path: 'wiki/kbe',
    component: KbeWikiComponent,
    data: { extraParameter: '' },
  },
  {
    path: 'wiki/marine',
    component: MarineComponent,
    data: { extraParameter: ''},
  },
  {
    path: 'apps/financeapp',
    component: FinanceappComponent,
    data: { extraParameter: ''},
  },
  {
    path: 'home/organogram',
    component: OrganogramComponent,
    data: { extraParameter: ''},
  },
  {
    path: 'apps/workshopapp',
    component: Workshopapp_v2Component,
    data: {extraParameter: ''},
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'apps/workshopapp_Beta',
    component: workshopAppV3Component,
    data: {extraParameter: ''},
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'apps/ict',
    component: IctComponent,
    data: {extraParameter: ''},
  },
  {
    path: 'apps/licenses',
    component: LicensesComponent,
    data: {extraParameter: ''}
  }
];

// Potential fix: https://stackoverflow.com/questions/35284988/angular-2-404-error-occur-when-i-refresh-through-the-browser
// https://stackoverflow.com/questions/62698023/refreshing-same-url-gives-404-not-found-in-angular
@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true
  }),
],
  exports: [RouterModule]
})
export class AppRoutingModule { }

