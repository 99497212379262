import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-Balie-Log',
  templateUrl: './Balie-Log.component.html',
  styleUrls: ['./Balie-Log.component.css']
})

// TEST LOG
export class BalieLogComponent implements OnInit {

  logDataBalie: [];

  httpOptions = {
    headers: new HttpHeaders({ 'content-type': 'application/json', Authorization: environment.token })
  };

  GetLogBalie() {
    this.http.get<any>(environment.afasLogBaliedirectUrl, this.httpOptions)
      .subscribe(data => {
        this.logDataBalie = data.rows;
      });
  }
  constructor(private http: HttpClient) { }

  ngOnInit() {
    this.GetLogBalie();
  }

}
