import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth.service';
import { IpService } from './ip.service';
import { Router } from '@angular/router';
import { JoyrideService } from 'ngx-joyride';
import {AfasDataService} from './afas-data.service';
import {DashboardAuthService} from './dashboard-auth.service';
import {MsalService} from '@azure/msal-angular';
import {GraphService} from './graph.service';
import {ReloadPageService} from "./page-reload/reload-page.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']

})
export class AppComponent implements OnInit{
  title = 'TWD App Dashboard';
  version = '1.3.2';
  public ipAddress: any;
  public dashboardAuthentication: any;
  public userInfo: any = [];

  constructor(
    public authService: AuthService,
    private ip: IpService,
    private router: Router,
    private joyride: JoyrideService,
    private afasDataService: AfasDataService,
    public dashboardAuthService: DashboardAuthService,
    private reloadPageService: ReloadPageService,

  ) {}

  ngOnInit() {
    this.signIn();
    this.getDashboardAuthentication();
    // this.reloadPageService.reloadCurrentRoute();

  }

  async signIn(): Promise<void> {
    await this.authService.signIn();
  }

  getDashboardAuthentication() {
    this.dashboardAuthService.getDashboardAuthentication().subscribe((res: any) => {
      this.dashboardAuthentication = res['0'];
      console.log(`dashboard auth: ${this.dashboardAuthentication}`);
    });
  }
}
