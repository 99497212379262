import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ExperthubDialogComponent} from './experthub-dialog/experthub-dialog.component';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';


@Component({
  selector: 'app-experthub',
  templateUrl: './experthub.component.html',
  styleUrls: ['./experthub.component.scss']
})
export class ExperthubComponent implements OnInit {

  public expertHubData: any = [];
  private blobUrl = 'https://twdarchivestore.file.core.windows.net/archive/Hosted_Files/experthub/skills.json?sv=2021-06-08&ss=bfqt&srt=sco&sp=r&se=2024-12-21T22:21:47Z&st=2022-12-21T14:21:47Z&spr=https&sig=f13x6nxMBBtVbwZQSyowYzbcdHNNAsyQV4r7%2FWZX94s%3D';

  constructor(private http: HttpClient, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.http.get(this.blobUrl).subscribe( (res) => {
      this.expertHubData = res;
      console.log(this.expertHubData);
      }
    );
  }

  openDialog(event: any) {
    this.dialog.open(ExperthubDialogComponent, {
      width: '1000px',
      data: {
        skills: event,
      },
    });

    console.log(`event_data: ${event.key}`);
  }
}
