import { Component, OnInit } from '@angular/core';
import {MobileTelephonyService} from './mobile-telephony.service';
import {MatDialog} from '@angular/material/dialog';
import {MobileTelephonyList} from '../../../models/mobileTelephony';
import {MatTableDataSource} from '@angular/material/table';
import {MobileTelephonyDialogComponent} from './mobile-telephony-dialog/mobile-telephony-dialog.component';

@Component({
  selector: 'app-mobile-telephony',
  templateUrl: './mobile-telephony.component.html',
  styleUrls: ['./mobile-telephony.component.css']
})
export class MobileTelephonyComponent implements OnInit {

  constructor(
    private getMobileListItems: MobileTelephonyService,
    public dialog: MatDialog,
  ) { }

  displayedColumns: string[] = ['owner', 'phoneType', 'phoneNum', 'expDateAbb', 'edit'];
  dataSource: MobileTelephonyList[] = [];
  isLoadingResults = false;
  isRateLimitReached = false;

  tableDataSource: MatTableDataSource<MobileTelephonyList>;

  ngOnInit(): void {
    this.getMobileListItems.getMobileListItems().subscribe((result) => {
      this.dataSource = result.rows;
      this.tableDataSource = new MatTableDataSource(this.dataSource);
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.tableDataSource.filter = filterValue.trim().toLowerCase();
  }

  openMobileListDialog(event: any, openedFromAdd: boolean) {
    const dialogRef = this.dialog.open(MobileTelephonyDialogComponent, {
      width: '1200px',
      height: '620px',
      data: {
        mobile: event,
        openedFromAdd
      },
    });
    console.log(event);

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

}
