import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {HardwareListService} from './hardware-list.service';
import {HardwareList} from '../../../models/hardwareList';
import {MatTableDataSource} from '@angular/material/table';
import {HardwareListDialogComponent} from './hardware-list-dialog/hardware-list-dialog.component';

@Component({
  selector: 'app-hardware-list',
  templateUrl: './hardware-list.component.html',
  styleUrls: ['./hardware-list.component.scss']
})
export class HardwareListComponent implements OnInit {

  constructor(
    private getHardwareListItems: HardwareListService,
    public dialog: MatDialog,
  ) { }

  displayedColumns: string[] = ['owner', 'pcName', 'pcModel', 'serialKey', 'encrypted', 'lastInstallDate', 'edit'];
  dataSource: HardwareList[] = [];
  isLoadingResults = false;
  isRateLimitReached = false;

  tableDataSource: MatTableDataSource<HardwareList>;

  ngOnInit(): void {
    this.getHardwareListItems.getHardwareListItems().subscribe((result) => {
      this.dataSource = result.rows;
      this.tableDataSource = new MatTableDataSource(this.dataSource);
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.tableDataSource.filter = filterValue.trim().toLowerCase();
  }

  openHardwareListDialog(event: any, openedFromAdd: boolean) {
    const dialogRef = this.dialog.open(HardwareListDialogComponent, {
      width: '1000px',
      data: {
        pc: event,
        openedFromAdd
      }
    });
    console.log(event);

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

}
