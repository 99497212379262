import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MailService {

  constructor(
    private httpClient: HttpClient,
  ) { }

  sendMail(event) {
    // define email for graph
    const graphMailLink = 'https://prod-26.westeurope.logic.azure.com/workflows/f4538250331b42759ff56ec22213c958/triggers/manual/paths/invoke/send_test_mail?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=eFULFgU9EfO7y085bVrnQyJwCXLBvMddAoxIyGP_bAc';

    // generate email body
    const requestBody = {
      from: 'notifications@twd.nl',
      subject: event.workshopTitle,
      body: 'Dear ' + event.attendeeABB + ',<br>\n' +
            'you signed out of <strong>' + event.workshopTitle + '</strong>' +
            '<br>Do not forget to delete the calendar item from your outlook calendar<br>' +
            '<br><br>',
      receipent: sessionStorage.userEmail
      };

    this.httpClient.post(graphMailLink, requestBody).subscribe((result) => {
      console.log('mail sent');
      }
    );

  }

}
