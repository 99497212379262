import { Component, OnInit} from '@angular/core';
import { AppComponent } from '../app.component';
import { AuthService } from '../auth.service';
import { JoyrideService } from 'ngx-joyride';
import {Router} from '@angular/router';
import {MailService} from '../mail-service/mail.service';
import {GraphService} from '../graph.service';
import {environment} from '../../environments/environment';


@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css',
]
})
export class NavBarComponent implements OnInit {

  // evironment
  displayEnv = environment.text;

  // Should the collapsed nav show?
  showNav: boolean;
  display = false;
  public userInfo: any = [];
  ipAddress: any;
  version = this.appData.version;
  navbarCollapsed: boolean;

  // define userphoto from sessionstorage
  userPhoto = sessionStorage.userPhoto;

  // Define functions for authorisation
  Finance = 'Finance';
  ICT = 'ICT';

  constructor(
    public authService: AuthService,
    public appData: AppComponent,
    private joyride: JoyrideService,
    private router: Router,
    private graphService: GraphService

    ) { }

  ngOnInit() {
    this.showNav = false;
    this.ipAddress = this.appData.ipAddress;
    // this.startTour();
    // const userAvatar = [{
    //   ''
    // }]this.graphService.user.avatar;
  }

  // Used by the Bootstrap navbar-toggler button to hide/show
  // the nav in a collapsed state
  toggleWiki() {
    this.display = !this.display;
  }

  async signIn(): Promise<void> {
    await this.router.navigate(['/']);
    await this.authService.signIn();
  }

  signOut(): void {
    this.authService.signOut();
  }

  startTour() {
    this.joyride.startTour(
        { steps: ['1', '2'] } // Your steps order
    );
  }
}
