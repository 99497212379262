<div class="row">
  <div class="col-10">
    <mat-form-field>
      <mat-label>Search...</mat-label>
      <input matInput (keyup)="applyFilter($event)">

    </mat-form-field>
  </div>
  <div class="col-2">
    <button mat-button  class="twd_btn" (click)="openPhoneListDialog('', true)">Add</button>
  </div>
</div>




<table mat-table [dataSource]="tableDataSource" matSort class="mat-elevation-z8">
  <!-- Product Name Column -->
  <ng-container matColumnDef="pcNumber">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> PC Number </th>
    <td mat-cell *matCellDef="let element">{{element.pcNumber}}</td>
  </ng-container>

  <ng-container matColumnDef="phoneNumber">
    <th mat-header-cell *matHeaderCellDef> Internal Number </th>
    <td mat-cell *matCellDef="let element"> {{element.phoneNumber}} </td>
  </ng-container>

  <ng-container matColumnDef="initials">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Initials </th>
    <td mat-cell *matCellDef="let element"> {{element.initials}} </td>
  </ng-container>

  <ng-container matColumnDef="edit">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <button mat-raised-button class="twd_btn" (click)="openPhoneListDialog(element, false)">Edit</button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<!-- PC_Nummer -->
