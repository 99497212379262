import { SelectionModel } from '@angular/cdk/collections';
import { HttpClient } from '@angular/common/http';
import {Component, Inject, OnInit, SchemaMetadata} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GetWorkshopDataService } from '../getWorkshopData.service';
import { AttendeeData } from '../../../models/workshop_links';
import {any} from 'codelyzer/util/function';
import {NotificationService} from '../../../notification-service/notification.service';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-workshop_attendees_dialog',
  templateUrl: './workshop_attendees_dialog.component.html',
  styleUrls: ['./workshop_attendees_dialog.component.css']
})
export class Workshop_attendees_dialogComponent implements OnInit {
  // Set loader for page default as false
  public isLoading = false;

  // general list of employee data
  public attendeesData: any = [];

  // attendees list for get afas functions
  public attendeesList: any = [];
  displayedColumns: string[] = ['select', 'Attendee', 'name', 'group', 'manager'];
  public tableData: any = [];


  selection = new SelectionModel<AttendeeData>(true, []);


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private httpClient: HttpClient,
    private workshopDataService: GetWorkshopDataService,
    private notificationService: NotificationService,
  ) { }

  async ngOnInit() {

    // initiate loader
    this.isLoading = true;
    // parse data to be used as JSON
    console.log(this.data);
    // console.log('attendees data; ', this.attendeesData)
    const user = (await this.workshopDataService.getFilteredAttendees(this.data.workshopId)).toPromise();
    this.attendeesList = (await user).rows;
    // disable loader
    this.isLoading = false;
    console.log(this.attendeesList);

    this.tableData = this.attendeesList;

    console.log('im done with list');




  }

  // Whether the number of selected elements matches the total number of rows.
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.tableData.length;
    return numSelected === numRows;
  }

  // Selects all rows if they are not all selected; otherwise clear selection.
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.tableData.forEach(row => this.selection.select(row));

  }

  updateAttendanceAfasData() {
    this.isLoading = true;
    for (const selection of this.attendeesList) {
        console.log('selection: ', selection);
        // Generate JSON for upload for selected items
        const presentieDataUpdateJson = {
          KnCourseSessionMember: {
            Element: {
              '@CrId': selection.eventId, // eventID
              '@SeId': selection.Code, // sessionCode
              '@MeId': selection.attendeeInternalCode, // internalCode
              '@CdId': selection.attendeeExternalCode, // External Code
              Fields: {
                Pres: selection.attendeeStatus // Present Y/N
              }
            }
          }
        };
        console.log('presentie data: ', presentieDataUpdateJson);
        this.httpClient.post(environment.workshopAttendanceUrl,
          JSON.stringify(presentieDataUpdateJson),
          this.workshopDataService.httpOptions).subscribe(
        data => this.notificationService.success('attendance updated'),
        error => this.notificationService.error('update failed: ' + error.error.externalMessage),
        () => this.isLoading = false);
      }

  }

  setAttendance(event, row) {
    console.log(row);
    row.attendeeStatus = event.checked;
    console.log(this.attendeesList);
    // row.attendeeStatus = this.selection.selected
  }

  setAllAttendance(event, rows) {
    console.log(rows);
}



}
